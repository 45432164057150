import React from 'react';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import {buildBlocks} from "../../../services/builder";
import Grid from "@material-ui/core/Grid/Grid";
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CgbTabContentInnerBlock = (props) => {
    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    let image = null;

    // This is a preview image
    image = <div className="gatsby-image-wrapper desktop-bg">
        <div style={{paddingBottom: '75%'}} />
        <img
            src={props.attributes.mediaUrl.charAt(0) === '/' ? 'https://brew-platform.staging.brew-systems.co.uk/' + props.attributes.mediaUrl : props.attributes.mediaUrl}
            alt=""
            style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center' }}
        />
    </div>

    return <div className={`tab-content ${props.attributes.className ? props.attributes.className : ''} ${classes.tabContentInner} ${classes.tabContentInnerTheme}`}>
        <Grid container spacing={3} direction="row">
            <Grid item xs={12} md={6} >
                {blocks.map((block) => {
                    return block;
                })}
            </Grid>
            <Grid item xs={12} md={6} >
                {image}
            </Grid>
        </Grid>
    </div>
};

export const CgbTabContentInnerBlockFragment = graphql`
  fragment CgbTabContentInnerBlock on WpBlock {
    ... on WpCgbTabContentInnerBlock {
        name
        attributes {
            className
            tabTitle
            mediaUrl
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbTabContentInnerBlockPreview = `
    ... on CgbTabContentInnerBlock {
        name
        attributes {
            className
            tabTitle
            mediaUrl
        }
    }
`;