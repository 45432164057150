import stamp from '../../../images/brand-stamp.png';

export default theme => ({
    list: {
        display: 'block',
        position: 'relative',
        marginBottom: 36,
        '& li': {
            fontSize: 18,
            letterSpacing: '0.032em',
            lineHeight: 1.75,
            marginBottom: 16,
            position: 'relative',
            display: 'block',
            paddingLeft: 80,
            '&::before': {
                content: '""',
                width: 22,
                height: 22,
                position: 'absolute',
                display: 'block',
                top: 10,
                left: 30,
                backgroundImage: 'url(' + stamp + ')',
                backgroundPosition: 'center',
                backgroundSize: 30,
            },
            '& a': {
                color: theme.palette.primary.main,
                '&:hover, &:focus': {
                    color: theme.palette.primary.dark,
                }
            }
        },
        '& ul, & ol': {
            marginTop: 0,
        },
        '&.indented': {
            margin: '0 4.1666666% 36px 10.25%',
        }
    },
})