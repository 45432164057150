import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import { buildBlocks } from "../../../services/builder";
import ReactHtmlParser from 'react-html-parser';
import styles from './styles';

import Form from './Form';

const useStyles = makeStyles(theme => styles(theme));

export const CgbContactFormBannerBlock = (props) => {
    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    // console.log(props);
    const classes = useStyles();

    return <div
        className={`banner-message ${props.attributes.className ? props.attributes.className : ''} ${classes.contactFormBanner} ${classes.contactFormBannerTheme}`}
    >
        {ReactHtmlParser(props.originalContent.replace(/1024px/g, "1440px"))}
        <div className="form-wrapper">
            {blocks.map((block) => {
                return block;
            })}
            <Form classes={classes} />
        </div>
    </div>
};

CgbContactFormBannerBlock.propTypes = {
    attributes: PropTypes.object.isRequired,
};

export const CgbContactFormBannerBlockFragment = graphql`
    fragment CgbContactFormBannerBlock on WpBlock {
        ... on WpCgbContactFormBannerBlock {
            name
            originalContent
            attributes {
                __typename
                ... on WpCgbContactFormBannerBlockAttributes {
                    className
                    mediaUrl
                }
            }
        }
    }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbContactFormBannerBlockPreview = `
    ... on CgbContactFormBannerBlock {
        name
        originalContent
        attributes {
            __typename
            ... on CgbContactFormBannerBlockAttributes {
                className
                mediaUrl
            }
        }
    }
`;