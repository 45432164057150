import themePalette from "../../../../.brew-cache/theme.json";

export default theme => ({
    para: {
        display: 'block',
        position: 'relative',
        fontSize: 16,
        letterSpacing: '0.024em',
        lineHeight: '2',
        '& a': {
            color: theme.palette.primary.main,
            textDecoration: 'underline !important',
            '&:hover, &:focus': {
                color: theme.palette.primary.dark,
            }
        },
        // Forground Colours
        '&[data-text-color="primary"]': { color: themePalette.ThemeOptions.mainColours.primaryColour + ' !important'},
        '&[data-text-color="secondary"]': { color: themePalette.ThemeOptions.mainColours.secondaryColour + ' !important'},
        '&[data-text-color="tertiary"]': { color: themePalette.ThemeOptions.mainColours.tertiaryColour + ' !important'},
        '&[data-text-color="foreground_primary"]': { color: themePalette.ThemeOptions.foregroundColours.foregroundPrimaryColour + ' !important'},
        '&[data-text-color="foreground_secondary"]': { color: themePalette.ThemeOptions.foregroundColours.foregroundSecondaryColour + ' !important'},
        '&[data-text-color="foreground_tertiary"]': { color: themePalette.ThemeOptions.foregroundColours.foregroundTertiaryColour + ' !important'},
        '&[data-text-color="background_primary"]': { color: themePalette.ThemeOptions.backgroundColours.backgroundPrimaryColour + ' !important'},
        '&[data-text-color="background_secondary"]': { color: themePalette.ThemeOptions.backgroundColours.backgroundSecondaryColour + ' !important'},
        '&[data-text-color="background_tertiary"]': { color: themePalette.ThemeOptions.backgroundColours.backgroundTertiaryColour + ' !important'},
        // Background Colours
        '&:not([data-bg-color="initial"])': {padding: 12},
        '&[data-bg-color="primary"]': { backgroundColor: themePalette.ThemeOptions.mainColours.primaryColour},
        '&[data-bg-color="secondary"]': { backgroundColor: themePalette.ThemeOptions.mainColours.secondaryColour},
        '&[data-bg-color="tertiary"]': { backgroundColor: themePalette.ThemeOptions.mainColours.tertiaryColour},
        '&[data-bg-color="foreground_primary"]': { backgroundColor: themePalette.ThemeOptions.foregroundColours.foregroundPrimaryColour},
        '&[data-bg-color="foreground_secondary"]': { backgroundColor: themePalette.ThemeOptions.foregroundColours.foregroundSecondaryColour},
        '&[data-bg-color="foreground_tertiary"]': { backgroundColor: themePalette.ThemeOptions.foregroundColours.foregroundTertiaryColour},
        '&[data-bg-color="background_primary"]': { backgroundColor: themePalette.ThemeOptions.backgroundColours.backgroundPrimaryColour},
        '&[data-bg-color="background_secondary"]': { backgroundColor: themePalette.ThemeOptions.backgroundColours.backgroundSecondaryColour},
        '&[data-bg-color="background_tertiary"]': { backgroundColor: themePalette.ThemeOptions.backgroundColours.backgroundTertiaryColour},
    },
    paraTheme: {
        ...theme.paragraphTheme,
    }
})