import arrow from "../../../images/arrow-right-purple.png";

export default theme => ({
    latestPosts: {
        marginBottom: 36,
        [theme.breakpoints.up('md')]: {
            padding: '75px 0',
        },
        '& .latest-posts-list': {
            marginTop: 20,
            '& .post-item': {
                marginBottom: 24,
                [theme.breakpoints.up('md')]: {
                    marginBottom: 36,
                },
                '& .post-content': {
                    overflow: 'hidden',
                    position: 'relative',
                    [theme.breakpoints.up('md')]: {
                        height: 270,
                        '&::after': {
                            position: 'absolute',
                            content: '""',
                            display: 'block',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            height: 1,
                            backgroundColor: theme.palette.background.default,
                            boxShadow: '0 0 20px 20px white',
                            zIndex: 5,
                        }
                    },
                },
                '& .date': {
                    fontSize: 12,
                    fontWeight: '500',
                    lineHeight: 1.2,
                    letterSpacing: '0.152em',
                    textTransform: 'uppercase',
                    marginTop: 13,
                },
                '& a': {
                    fontSize: 18,
                    color: theme.palette.primary.main,
                    lineHeight: 1.5,
                    marginBottom: 20,
                    fontWeight: '500',
                    '&::after': {
                        display: 'inline-block',
                        content: '""',
                        marginLeft: 24,
                        width: 31,
                        fontWeight: '500',
                        height: 19,
                        backgroundImage: 'url(' + arrow + ')',
                        backgroundSize: 'cover',
                        verticalAlign: 'middle',
                        transition: 'margin-left .3s ease-in-out',
                    },
                    '&:hover, &:focus': {
                        '&::after': {
                            marginLeft: 32,
                        },
                    },
                    [theme.breakpoints.up('md')]: {
                        fontSize: 24,
                    }
                }
            }
        },
        '& .MuiGrid-item': {
            paddingTop: '12px !important',
            paddingBottom: '12px !important',
        }
    },
    latestPostsTheme: {
        ...theme.latestPostsTheme,
    }
})