import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from "gatsby";
import { makeStyles } from '@material-ui/styles';
import { buildBlocks } from "../../../services/builder";
import styles from "./styles";
import { Grid } from '@material-ui/core';
import Link from '../../Link';

const useStyles = makeStyles(theme => styles(theme));

export const CgbImageLinkRowInnerBlock = (props) => {
    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    return <Grid item xs={12} sm={6} md={3}>
        <Link to={props.attributes.link} className={`image-link-row-inner ${props.attributes.className ? props.attributes.className : ''} ${classes.imageLinkRowInner} ${classes.imageLinkRowInnerTheme}`} >
            {blocks.map((block) => {
                return block;
            })}
            <span>{props.attributes.text}</span>
        </Link>
    </Grid>
    
    
};

CgbImageLinkRowInnerBlock.propTypes = {
    attributes: PropTypes.object.isRequired,
};

export const CgbImageLinkRowInnerBlockFragment = graphql`
    fragment CgbImageLinkRowInnerBlock on WpBlock {
        ... on WpCgbImageLinkRowInnerBlock {
            name
            attributes {
                __typename
                ... on WpCgbImageLinkRowInnerBlockAttributes {
                    className
                    link
                    text 
                }
            }
        }
    }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbImageLinkRowInnerBlockPreview = `
    ... on CgbImageLinkRowInnerBlock {
        name
        attributes {
            __typename
            ... on CgbImageLinkRowInnerBlockAttributes {
                className
                link
                text 
            }
        }
    }
`;