export default theme => ({
    bannerContent: {
        position: 'relative',
        marginBottom: 36,
        fontSize: 0,
        '&.overlay': {
            '&::before': {
                content: '""',
                backgroundColor: theme.palette.primary.main,
                top: 0,
                bottom: 0,
                width: '100vw',
                left: '50%',
                transform: 'translateX(-50%)',
                zIndex: 1,
                position: 'absolute',
                opacity: .5,
            },
        },
        '& > .gatsby-image-wrapper': {
            height: '595px !important',
            marginLeft: '50%',
            transform: 'translateX(-50%)',
            maxWidth: '100vw !important',
            width: 'calc(100% + 50px) !important',
            zIndex: '0',
            [theme.breakpoints.up('md')]: {
                height: 'auto !important',
                width: '100vw !important',
                '& > div': {
                    paddingBottom: '50% !important',
                }
            },
        },
        '& .content-wrapper': {
            position: 'absolute',
            top: '50%',
            maxWidth: 530,
            transform: 'translateY(-50%)',
            zIndex: 3,
            width: '100%',
            [theme.breakpoints.up('md')]: {
                right: 0,
            },
            '& > *, & > * a': {
                color: theme.palette.primary.contrastText,
            },
            '& h1, & h2, & h3, & h4, & h5, & h6': {
                marginBottom: 24,
            },
            '& p': {
                paddingLeft: 12,
                marginBottom: 60,
                [theme.breakpoints.up('md')]: {
                    paddingLeft: 80,
                }
            },
            '& button': {
                marginLeft: 12,
                minWidth: 260,
                padding: '20px 22px !important',
                [theme.breakpoints.up('md')]: {
                    marginLeft: 80,
                }
            },
            '&::before': {
                content: '""',
                width: 100,
                height: 100,
                backgroundColor: theme.palette.primary.main,
                display: 'block',
                position: 'absolute',
                transform: 'rotate(45deg)',
                zIndex: -1,
                right: -40,
                top: 20,
                boxShadow: '0 3px 6px rgba(0,0,0,0.16)',
                opacity: 0.75,
                [theme.breakpoints.up('md')]: {
                    width: 200,
                    height: 200,
                    top: 40,
                    right: -100
                }
            },
        },
        '&.left-top .gatsby-image-wrapper img': {
            objectPosition: 'left top !important',
        },
        '&.left-center .gatsby-image-wrapper img': {
            objectPosition: 'left center !important',
        },
        '&.left-bottom .gatsby-image-wrapper img': {
            objectPosition: 'left bottom !important',
        },
        '&.center-top .gatsby-image-wrapper img': {
            objectPosition: 'center top !important',
        },
        '&.center-bottom .gatsby-image-wrapper img': {
            objectPosition: 'center bottom !important',
        },
        '&.right-top .gatsby-image-wrapper img': {
            objectPosition: 'right top !important',
        },
        '&.right-center .gatsby-image-wrapper img': {
            objectPosition: 'right center !important',
        },
        '&.right-bottom .gatsby-image-wrapper img': {
            objectPosition: 'right bottom !important',
        },
        '&.secondary-accent': {
            '& .content-wrapper': {
                '&::before': {
                    backgroundColor: theme.palette.secondary.main,
                },
            },
        },
        '&.tertiary-accent': {
            '& .content-wrapper': {
                '&::before': {
                    backgroundColor: theme.palette.tertiary.main
                },
            },
        },
        '&.left-content': {
            '& .content-wrapper': {
                [theme.breakpoints.up('md')]: {
                    right: 'auto',
                    '& p': {
                        paddingLeft: 0,
                    },
                    '& button': {
                        marginLeft: 0,
                    },
                },
                '&::before': {
                    right: 'auto',
                    left: '-40px',
                    [theme.breakpoints.up('md')]: {
                        left: '-100px',
                    },
                },
            }
        }
    },
    bannerContentTheme: {
        ...theme.bannerContentTheme,
    }
});