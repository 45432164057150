export default theme => ({
    twoColWrapper: {
        position: 'relative',
        marginBottom: 36,
        '& .MuiGrid-container': {
            margin: '-12px -40px 0 !important',
        },
        '& .MuiGrid-item': {
            paddingTop: '12px !important',
            paddingBottom: '12px !important',
        }
    },
    twoColWrapperTheme: {
        // ...theme.twoColWrapperTheme,
    }
})