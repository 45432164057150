export default theme => ({
    landingVideo: {
        width: 'calc(100vw)',
        marginLeft: '50%',
        transform: 'translateX(-50%)',
        position: 'relative',
        marginBottom: 36,
        backgroundSize: 'cover',
        paddingBottom: '56.3vw',
        marginTop: 84,
        [theme.breakpoints.up('md')]: {
            marginTop: 0,
            '&::before': {
                content: '""',
                display: 'block',
                height: 128,
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                background: 'linear-gradient(0deg, rgba(72,32,56,0) 0%, rgba(72,32,56,0.4) 22%, rgba(72,32,56,0.72) 100%)',
                zIndex: 2,
            }
        },
        '& > div': {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            '& video': {
                width: '100%',
            }
        },
        '& .play-button': {
            maxWidth: '33.333%',
        },
        '& .react-player__preview': {
            backgroundImage: 'none !important',
        }
    },
});