import React from 'react';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import {buildBlocks} from "../../../services/builder";
import styles from './styles';
import { Grid } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';

const useStyles = makeStyles(theme => styles(theme));

export const CgbTwoCardIntroBlock = (props) => {
    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    return <div className={`two-card-intro ${props.attributes.className ? props.attributes.className : ''} ${classes.twoCardIntro} ${classes.twoCardIntroTheme}`}>
        <Grid container spacing={3} direction="row">
            <Grid item xs={12} sm={11}>
                <div className="con-col">
                    <Grid container spacing={3} direction="row">
                        <Grid item xs={1} />
                        <Grid item xs={9} md={8} lg={7}>           
                            <h2 className="tag-title">{ReactHtmlParser(props.attributes.tag)}</h2>
                        </Grid>   
                        <Grid item xs={1} md={3} lg={4}/>  
                        <Grid item xs={2} /> 
                        <Grid item xs={8} md={7} lg={6}>
                            {blocks[0]}
                            {blocks[1]}
                        </Grid>
                    </Grid>
                </div>
                <div className="cards">
                    <Grid container spacing={3} direction="row">
                        <Grid item xs={1} lg={2} />
                        <Grid item xs={10} lg={8}>
                            <Grid container spacing={3} direction="row">
                                <Grid item xs={12} md={6} className="alt-card">
                                    {blocks[2]}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {blocks[3]}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    </div>
};

export const CgbTwoCardIntroBlockFragment = graphql`
  fragment CgbTwoCardIntroBlock on WpBlock {
    ... on WpCgbTwoCardIntroBlock {
        name
        attributes {
            tag
            className
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbTwoCardIntroBlockPreview = `
    ... on CgbTwoCardIntroBlock {
        name
        attributes {
            tag
            className
        }
    }
`;