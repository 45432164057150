import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import { buildBlocks } from "../../../services/builder";
import ReactHtmlParser from 'react-html-parser';
import Link from "../../Link";
import Grid from '@material-ui/core/Grid';
import GoBack from '../../GoBack';
import styles from './styles';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';

const useStyles = makeStyles(theme => styles(theme));

export const CgbBlogPostBannerBlock = (props) => {
    // console.log(props);
    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    const data = useStaticQuery(graphql`
      query siteUrlGrab {
        wp {
          locationData {
            LocationData {
              url
            }
          }
        }
      }
    `);

    return <div
        className={`blog-post-banner ${props.attributes.className ? props.attributes.className : ''} ${classes.blockPostBanner} ${classes.blockPostBannerTheme}`}
    >
        <Grid container spacing={0} direction="row" className="top">
            <Grid item xs={12} md={8} lg={7} className="image">
                {ReactHtmlParser(props.originalContent.replace(/1024px/g, "1440px"))}
            </Grid>
            <Grid item xs={12} md={4} lg={5} className="content">
                <p className="date">{props.pageContext.date}</p>
                {blocks.map((block) => {
                    return block;
                })}
            </Grid>
        </Grid>
        <Grid container spacing={0} direction="row" className="bottom">
            <Grid item xs={6}>
                <Link to="/blog" className="go-back">Back</Link>
            </Grid>
            <Grid item xs={6}>
                <div className="social-share">
                    <span>Share</span>
                    <Link className="twitter" to={"https://twitter.com/intent/tweet?text=" + props.pageContext.pageTitle + "&url=" + data.wp.locationData.LocationData.url + props.pageContext.pagePath}><TwitterIcon/></Link>
                    <Link className="facebook" to={"https://www.facebook.com/sharer/sharer.php?u=" + data.wp.locationData.LocationData.url + props.pageContext.pagePath}><FacebookIcon/></Link>
                </div>
            </Grid>
        </Grid>
    </div>
};

CgbBlogPostBannerBlock.propTypes = {
    attributes: PropTypes.object.isRequired,
};

export const CgbBlogPostBannerBlockFragment = graphql`
    fragment CgbBlogPostBannerBlock on WpBlock {
        ... on WpCgbBlogPostBannerBlock {
            name
            originalContent
            attributes {
                __typename
                ... on WpCgbBlogPostBannerBlockAttributes {
                    className
                    mediaUrl
                }
            }
        }
    }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbBlogPostBannerBlockPreview = `
    ... on CgbBlogPostBannerBlock {
        name
        originalContent
        attributes {
            __typename
            ... on CgbBlogPostBannerBlockAttributes {
                className
                mediaUrl
            }
        }
    }
`;