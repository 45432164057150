export default theme => ({
    contactFormBanner: {
        position: 'relative',
        marginBottom: 36,
        padding: '40px 0 50px',
        [theme.breakpoints.up('md')]: {
            padding: '80px 0 100px',
        },
        '& .gatsby-image-wrapper': {
            width: '100vw !important',
            maxWidth: '100vw !important',
            position: 'absolute !important',
            top: 0,
            bottom: 0,
            left: '50%',
            transform: 'translateX(-50%)',
        },
        '& .form-wrapper': {
            position: 'relative',
            zIndex: 2,
            backgroundColor: theme.palette.primary.main,
            padding: 24,
            maxWidth: 680,
            [theme.breakpoints.up('md')]: {
                padding: 50,
            },
            '& h2': {
                fontFamily: theme.headerFont,
                marginTop: 0,
                fontWeight: 'bold',
                letterSpacing: '0',
                lineHeight: 1.2,
                marginBottom: 24,
                color: theme.palette.primary.contrastText,
                [theme.breakpoints.up('md')]: {
                    fontSize: 32,
                }
            },
            '& p': {
                fontSize: 14,
                color: theme.palette.primary.contrastText,
                marginBottom: 24,
                lineHeight: 1.5,
                letterSpacing: '0.032em',
            },
            '& form': {

                '& fieldset': {
                    padding: 0,
                    border: 'none',
                },
                '& label': {
                    fontFamily: theme.bodyFont,
                    letterSpacing: '0.152em',
                    fontSize: 14,
                    textTransform: 'uppercase',
                    fontWeight: 500,
                    lineHeight: '16px',
                    marginBottom: 5,
                    display: 'block',
                    color: theme.palette.primary.contrastText,
                },
                '& input, & textarea, & select': {
                    border: 'none',
                    borderBottom: '2px solid white',
                    padding: '12px 0',
                    color: 'white',
                    letterSpacing: '-0.02em',
                    fontSize: 20,
                    fontFamily: theme.bodyFont,
                    backgroundColor: 'transparent !important',
                    marginBottom: 32,
                    display: 'block',
                    width: '100%',
                    outline: 'none',
                },
                '& button': {
                    fontFamily: theme.bodyFont,
                    padding: '12px 22px 14px',
                    boxShadow: '0 4px 6px rgba(0,0,0,0.5)',
                    fontSize: 17,
                    lineHeight: 1.2,
                    fontWeight: 500,
                    textAlign: 'center',
                    minWidth: 185,
                    border: 'none',
                    outline: 'none',
                    borderRadius: 4,
                    textTransform: 'none',
                    color: theme.palette.primary.main,
                    backgroundColor: theme.palette.primary.contrastText,
                    display: 'flex',
                    margin: '0 0 0 auto',
                    justifyContent: 'center',
                    transition: 'background-color .3s ease-in-out',
                    '&:hover, &:focus': {
                        backgroundColor: theme.palette.secondary.light,
                    }
                }
            }
        }
    },
    contactFormBannerTheme: {
        ...theme.contactFormBannerTheme,
    },
    Form: {}
});