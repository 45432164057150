import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import Typography from "@material-ui/core/Typography";
import { transformLink } from '../../../helpers/';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CoreQuoteBlock = (props) => {
  // console.log(props);
  const classes = useStyles();

  return <div className={`quote-block ${props.attributes.className ? props.attributes.className : ''} ${classes.quote}`}>
      <div className="quote-text">
        {ReactHtmlParser(props.attributes.value, {transform : transformLink})}
      </div>
      <Typography
        component="p"
        variant="body1"
        className={props.attributes.className ? props.attributes.className : ''}
        paragraph={true}
    >
        {ReactHtmlParser(props.attributes.citation, {transform : transformLink})}
    </Typography>
  </div>
};

CoreQuoteBlock.propTypes = {
    attributes: PropTypes.object.isRequired,
};

// This is the GraphQL Fragment that will be used within Gatsby queries
export const CoreQuoteBlockFragment = graphql`
  fragment CoreQuoteBlock on WpBlock {
    ...on WpCoreQuoteBlock {
      name
      attributes {
        __typename
        ...on WpCoreQuoteBlockAttributes {
          align
          citation
          className
          value            
        }
      }
    }
  }`;

  export const CoreQuoteBlockPreview = `
  ...on CoreQuoteBlock {
    name
    attributes {
      __typename
      ...on CoreQuoteBlockAttributes {
        align
        citation
        className
        value            
      }
    }
  }
  `;