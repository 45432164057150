export default theme => ({
    featuredText: {
        position: 'relative',
        padding: '75px 40px 90px',
        backgroundColor: theme.palette.secondary.main,
        [theme.breakpoints.up('md')]: {
            padding: '60px',
            width: '100%',
            margin: '0 0 32px',
        },
        '& > p': {
            color: theme.palette.secondary.contrastText,
            fontSize: 32,
            fontWeight: 'bold',
            fontFamily: theme.headerFont,
            lineHeight: 1.25,
            margin: 0,
            [theme.breakpoints.up('md')]: {
                fontSize: 46,
            },
        },
        '&.primary-bg': {
            backgroundColor: theme.palette.primary.main,
            '& > p': {
                color: theme.palette.primary.contrastText,
            },
        },
        '&.tertiary-bg': {
            backgroundColor: theme.palette.tertiary.main,
            '& > p': {
                color: theme.palette.tertiary.contrastText,
            },
        }
    },
    featuredTextTheme: {
        ...theme.featuredTextTheme
    },
})