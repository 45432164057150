import React from "react";
import { replaceMasks } from '../services/masks';

import {CoreBlock} from "../components/blocks/CoreBlock";
import {CoreParagraphBlock} from "../components/blocks/CoreParagraphBlock/";  
import {CoreHeadingBlock} from "../components/blocks/CoreHeadingBlock/";  
import {CoreColumnBlock} from "../components/blocks/CoreColumnBlock/";  
import {CoreColumnsBlock} from "../components/blocks/CoreColumnsBlock/";  
import {CoreImageBlock} from "../components/blocks/CoreImageBlock/";  
import {CoreButtonsBlock} from "../components/blocks/CoreButtonsBlock/";  
import {CoreButtonBlock} from "../components/blocks/CoreButtonBlock/";  
import {CoreGalleryBlock} from "../components/blocks/CoreGalleryBlock/";  
import {CoreQuoteBlock} from "../components/blocks/CoreQuoteBlock/";  
import {CoreListBlock} from "../components/blocks/CoreListBlock/";  
import {CoreTableBlock} from "../components/blocks/CoreTableBlock/";  
import {CoreFileBlock} from "../components/blocks/CoreFileBlock/";  
import {CoreSpacerBlock} from "../components/blocks/CoreSpacerBlock/";  
import {GravityformsFormBlock} from "../components/blocks/GravityformsFormBlock";  
import {CgbAccordionBlock} from "../components/blocks/CgbAccordionBlock"; 
import {CgbAccordionInnerBlock} from "../components/blocks/CgbAccordionInnerBlock"; 
import {CgbBannerCalloutCtaBlock} from "../components/blocks/CgbBannerCalloutCtaBlock"; 
import {CgbBannerContentBlock} from "../components/blocks/CgbBannerContentBlock";
import {CgbBannerMessageBlock} from "../components/blocks/CgbBannerMessageBlock"; 
import {CgbBlogPagePostsBlock} from "../components/blocks/CgbBlogPagePostsBlock";
import {CgbBlogPostBannerBlock} from "../components/blocks/CgbBlogPostBannerBlock"; 
import {CgbBlogPostWrapBlock} from "../components/blocks/CgbBlogPostWrapBlock";  
import {CgbBreakoutGalleryIntroBlock} from "../components/blocks/CgbBreakoutGalleryIntroBlock";
import {CgbCalendarAppBlock} from "../components/blocks/CgbCalendarAppBlock"; 
import {CgbCalloutCtaBlock} from "../components/blocks/CgbCalloutCtaBlock"; 
import {CgbCardBlock} from "../components/blocks/CgbCardBlock" ;
import {CgbCardColumnsBlock} from "../components/blocks/CgbCardColumnsBlock";
import {CgbCardRowBlock} from "../components/blocks/CgbCardRowBlock" ;
import {CgbContactCalloutBlock} from "../components/blocks/CgbContactCalloutBlock";
import {CgbContactFormBannerBlock} from "../components/blocks/CgbContactFormBannerBlock";
import {CgbContactPageIntroBlock} from "../components/blocks/CgbContactPageIntroBlock";
import {CgbFeaturedIconsBlock} from "../components/blocks/CgbFeaturedIconsBlock"; 
import {CgbFeaturedIconsInnerBlock} from "../components/blocks/CgbFeaturedIconsInnerBlock"; 
import {CgbFeaturedTextBlock} from "../components/blocks/CgbFeaturedTextBlock"; 
import {CgbFileListBlock} from "../components/blocks/CgbFileListBlock";  
import {CgbFileListInnerBlock} from "../components/blocks/CgbFileListInnerBlock";  
import {CgbFileListInnerSmallBlock} from "../components/blocks/CgbFileListInnerSmallBlock";  
import {CgbGoodToGoBlock} from "../components/blocks/CgbGoodToGoBlock";  
import {CgbIconListIntroBlock} from "../components/blocks/CgbIconListIntroBlock";  
import {CgbIconListInnerBlock} from "../components/blocks/CgbIconListInnerBlock";  
import {CgbIconListBlock} from "../components/blocks/CgbIconListBlock";  
import {CgbImageBreakdownBlock} from "../components/blocks/CgbImageBreakdownBlock";  
import {CgbImageBreakdownInnerBlock} from "../components/blocks/CgbImageBreakdownInnerBlock";  
import {CgbImageContentRowsBlock} from "../components/blocks/CgbImageContentRowsBlock";  
import {CgbImageContentRowsInnerBlock} from "../components/blocks/CgbImageContentRowsInnerBlock";  
import {CgbImageGalleryGridBlock} from "../components/blocks/CgbImageGalleryGridBlock";  
import {CgbImageGalleryGridInnerLargeBlock} from "../components/blocks/CgbImageGalleryGridInnerLargeBlock";  
import {CgbImageGalleryGridInnerSmallBlock} from "../components/blocks/CgbImageGalleryGridInnerSmallBlock";  
import {CgbImageLinkRowBlock} from "../components/blocks/CgbImageLinkRowBlock"; 
import {CgbImageLinkRowInnerBlock} from "../components/blocks/CgbImageLinkRowInnerBlock"; 
import {CgbImageLinkSliderBlock} from "../components/blocks/CgbImageLinkSliderBlock"; 
import {CgbImageLinkSliderInnerBlock} from "../components/blocks/CgbImageLinkSliderInnerBlock"; 
import {CgbImageSliderBlock} from "../components/blocks/CgbImageSliderBlock";  
import {CgbIntroGalleryBlock} from "../components/blocks/CgbIntroGalleryBlock";  
import {CgbIntroGallerySplitBlock} from "../components/blocks/CgbIntroGallerySplitBlock";  
import {CgbIntroImageSliderBlock} from "../components/blocks/CgbIntroImageSliderBlock";  
import {CgbLandingVideoBlock} from "../components/blocks/CgbLandingVideoBlock";
import {CgbLatestPostsBlock} from "../components/blocks/CgbLatestPostsBlock";  
import {CgbLatestPostsGridBlock} from "../components/blocks/CgbLatestPostsGridBlock";  
import {CgbNextPreviousPostsBlock} from "../components/blocks/CgbNextPreviousPostsBlock";  
import {CgbOfferGridBlock} from "../components/blocks/CgbOfferGridBlock";  
import {CgbOfferGridInnerBlock} from "../components/blocks/CgbOfferGridInnerBlock";  
import {CgbPageTitleBlock} from "../components/blocks/CgbPageTitleBlock";  
import {CgbPullquoteBlock} from "../components/blocks/CgbPullquoteBlock";  
import {CgbSeparatorBlock} from "../components/blocks/CgbSeparatorBlock"; 
import {CgbSocialSharingBlock} from "../components/blocks/CgbSocialSharingBlock"; 
import {CgbTabContentBlock} from "../components/blocks/CgbTabContentBlock";  
import {CgbTabContentInnerBlock} from "../components/blocks/CgbTabContentInnerBlock";  
import {CgbTestimonialBlock} from "../components/blocks/CgbTestimonialBlock";  
import {CgbThickIntroBlock} from "../components/blocks/CgbThickIntroBlock";  
import {CgbThinIntroBlock} from "../components/blocks/CgbThinIntroBlock";  
import {CgbTwoCardIntroBlock} from "../components/blocks/CgbTwoCardIntroBlock"; 
import {CgbTwoCardSplitBlock} from "../components/blocks/CgbTwoCardSplitBlock";  
import {CgbTwoColIntroBlock} from "../components/blocks/CgbTwoColIntroBlock";  
import {CgbTwoColWrapperBlock} from "../components/blocks/CgbTwoColWrapperBlock";  
import {CgbTwoColInnerBlock} from "../components/blocks/CgbTwoColInnerBlock";  
import {CgbVideoBannerMessageBlock} from "../components/blocks/CgbVideoBannerMessageBlock";  
import {CgbVideoEmbedBlock} from "../components/blocks/CgbVideoEmbedBlock";
import {CgbVideoTestimonialBlock} from "../components/blocks/CgbVideoTestimonialBlock"; 


/**
 * Returns the component for the block name
 * @param {String} name 
 */
function mapBlocks(name){
    let block = null;
    switch(name){
        case 'CoreBlock': block = CoreBlock; break;
        case 'CoreParagraphBlock': block =  CoreParagraphBlock; break;  
        case 'CoreHeadingBlock': block =  CoreHeadingBlock; break;  
        case 'CoreColumnBlock': block =  CoreColumnBlock; break;  
        case 'CoreColumnsBlock': block =  CoreColumnsBlock; break;  
        case 'CoreImageBlock': block =  CoreImageBlock; break;  
        case 'CoreButtonsBlock': block =  CoreButtonsBlock; break;  
        case 'CoreButtonBlock': block =  CoreButtonBlock; break;  
        case 'CoreGalleryBlock': block =  CoreGalleryBlock; break;  
        case 'CoreQuoteBlock': block =  CoreQuoteBlock; break;  
        case 'CoreListBlock': block =  CoreListBlock; break;  
        case 'CoreTableBlock': block =  CoreTableBlock; break;  
        case 'CoreFileBlock': block =  CoreFileBlock; break;  
        case 'CoreSpacerBlock': block =  CoreSpacerBlock; break;  
        case 'GravityformsFormBlock': block =  GravityformsFormBlock; break;  
        case 'CgbAccordionBlock': block =  CgbAccordionBlock; break; 
        case 'CgbAccordionInnerBlock': block =  CgbAccordionInnerBlock; break; 
        case 'CgbBannerCalloutCtaBlock': block =  CgbBannerCalloutCtaBlock; break; 
        case 'CgbBannerMessageBlock': block =  CgbBannerMessageBlock; break; 
        case 'CgbBannerContentBlock': block = CgbBannerContentBlock; break;
        case 'CgbBlogPagePostsBlock': block =  CgbBlogPagePostsBlock; break; 
        case 'CgbBlogPostBannerBlock' : block = CgbBlogPostBannerBlock; break;
        case 'CgbBlogPostWrapBlock': block =  CgbBlogPostWrapBlock; break;  
        case 'CgbBreakoutGalleryIntroBlock': block =  CgbBreakoutGalleryIntroBlock; break;
        case 'CgbCalendarAppBlock': block = CgbCalendarAppBlock; break;
        case 'CgbCalloutCtaBlock': block =  CgbCalloutCtaBlock; break;
        case 'CgbCardBlock': block =  CgbCardBlock; break;
        case 'CgbCardColumnsBlock' : block = CgbCardColumnsBlock; break;
        case 'CgbCardRowBlock': block =  CgbCardRowBlock; break;
        case 'CgbContactCalloutBlock': block =  CgbContactCalloutBlock; break;
        case 'CgbContactFormBannerBlock' : block = CgbContactFormBannerBlock; break;
        case 'CgbContactPageIntroBlock' : block = CgbContactPageIntroBlock; break;
        case 'CgbFeaturedIconsBlock': block =  CgbFeaturedIconsBlock; break; 
        case 'CgbFeaturedIconsInnerBlock': block =  CgbFeaturedIconsInnerBlock; break; 
        case 'CgbFeaturedTextBlock': block =  CgbFeaturedTextBlock; break; 
        case 'CgbFileListBlock': block =  CgbFileListBlock; break;  
        case 'CgbFileListInnerBlock': block =  CgbFileListInnerBlock; break;  
        case 'CgbFileListInnerSmallBlock': block =  CgbFileListInnerSmallBlock; break;  
        case 'CgbGoodToGoBlock': block =  CgbGoodToGoBlock; break;  
        case 'CgbIconListIntroBlock': block =  CgbIconListIntroBlock; break;  
        case 'CgbIconListInnerBlock': block =  CgbIconListInnerBlock; break;  
        case 'CgbIconListBlock': block =  CgbIconListBlock; break;  
        case 'CgbImageBreakdownBlock': block =  CgbImageBreakdownBlock; break;  
        case 'CgbImageBreakdownInnerBlock': block =  CgbImageBreakdownInnerBlock; break;  
        case 'CgbImageContentRowsBlock': block =  CgbImageContentRowsBlock; break;  
        case 'CgbImageContentRowsInnerBlock': block =  CgbImageContentRowsInnerBlock; break;  
        case 'CgbImageGalleryGridBlock': block =  CgbImageGalleryGridBlock; break;  
        case 'CgbImageGalleryGridInnerLargeBlock': block =  CgbImageGalleryGridInnerLargeBlock; break;  
        case 'CgbImageGalleryGridInnerSmallBlock': block =  CgbImageGalleryGridInnerSmallBlock; break;  
        case 'CgbImageLinkRowBlock': block =  CgbImageLinkRowBlock; break; 
        case 'CgbImageLinkRowInnerBlock': block =  CgbImageLinkRowInnerBlock; break; 
        case 'CgbImageLinkSliderBlock': block =  CgbImageLinkSliderBlock; break; 
        case 'CgbImageLinkSliderInnerBlock': block =  CgbImageLinkSliderInnerBlock; break; 
        case 'CgbImageSliderBlock': block =  CgbImageSliderBlock; break;  
        case 'CgbIntroGalleryBlock': block =  CgbIntroGalleryBlock; break;  
        case 'CgbIntroGallerySplitBlock': block =  CgbIntroGallerySplitBlock; break;  
        case 'CgbIntroImageSliderBlock': block =  CgbIntroImageSliderBlock; break;  
        case 'CgbLandingVideoBlock': block = CgbLandingVideoBlock; break;
        case 'CgbLatestPostsBlock': block =  CgbLatestPostsBlock; break;  
        case 'CgbLatestPostsGridBlock': block =  CgbLatestPostsGridBlock; break;  
        case 'CgbNextPreviousPostsBlock': block =  CgbNextPreviousPostsBlock; break;  
        case 'CgbOfferGridBlock': block =  CgbOfferGridBlock; break;  
        case 'CgbOfferGridInnerBlock': block =  CgbOfferGridInnerBlock; break;  
        case 'CgbPageTitleBlock': block =  CgbPageTitleBlock; break;  
        case 'CgbPullquoteBlock': block =  CgbPullquoteBlock; break;  
        case 'CgbSeparatorBlock': block =  CgbSeparatorBlock; break; 
        case 'CgbSocialSharingBlock': block =  CgbSocialSharingBlock; break; 
        case 'CgbTabContentBlock': block =  CgbTabContentBlock; break;  
        case 'CgbTabContentInnerBlock': block =  CgbTabContentInnerBlock; break;  
        case 'CgbTestimonialBlock': block =  CgbTestimonialBlock; break;  
        case 'CgbThickIntroBlock': block =  CgbThickIntroBlock; break;  
        case 'CgbThinIntroBlock': block =  CgbThinIntroBlock; break;  
        case 'CgbTwoCardIntroBlock': block =  CgbTwoCardIntroBlock; break;  
        case 'CgbTwoCardSplitBlock': block =  CgbTwoCardSplitBlock; break;  
        case 'CgbTwoColIntroBlock': block =  CgbTwoColIntroBlock; break;  
        case 'CgbTwoColWrapperBlock': block =  CgbTwoColWrapperBlock; break;  
        case 'CgbTwoColInnerBlock': block =  CgbTwoColInnerBlock; break;  
        case 'CgbVideoBannerMessageBlock': block =  CgbVideoBannerMessageBlock; break;  
        case 'CgbVideoEmbedBlock': block = CgbVideoEmbedBlock; break;
        case 'CgbVideoTestimonialBlock': block =  CgbVideoTestimonialBlock; break; 
        default:
            block = null
        break;
    }
    return block;
}

function UCFirst(string) 
{
    return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Converts the block name in to a component name
 * eg. core/banner = CoreBannerBlock
 * @param {String} name 
 */
function convertName(name)
{
    const split = name.split('/');
    const parts = [];
    parts.push(UCFirst(split[0]));
    const split2 = split[1].split('-');
    split2.map(part => {
        return parts.push(UCFirst(part));
    });
    return parts.join('') + (name === 'core/block' ? '' : 'Block');
}

export const buildBlocks = (blocks, parentAttributes = {}, pageContext = {}) => {
    if (! blocks) return [];
    // Render the blocks
    let processed = [];
    blocks.map((block, index) => {
        block.attributes = replaceMasks(block.attributes)
        let elem = null;

        // Convert the block name to a component name
        const component = convertName(block.name);
        let OutputBlock = null;

        OutputBlock = mapBlocks(component)

        elem = OutputBlock ? <OutputBlock 
            key={index} 
            attributes={block.attributes} 
            parentAttributes={parentAttributes} 
            originalContent={block.originalContent}
            innerBlocks={component==='CoreBlock' ? block.reusableBlock.blocks : block.innerBlocks} 
            pageContext={pageContext} 
        /> : null;

        return processed.push(elem);

    });
    return processed;
};