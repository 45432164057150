import React from 'react';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import {buildBlocks} from "../../../services/builder";
import Grid from "@material-ui/core/Grid/Grid";
import styles from "./styles";

const useStyles = makeStyles(theme => styles(theme));

export const CgbImageContentRowsInnerBlock = (props) => {
    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    return <Grid container spacing={0} direction="row" justify="center" alignItems="center" className={`offer-grid-inner ${props.attributes.className ? props.attributes.className : ''} ${classes.imageContentRowsInner} ${classes.imageContentRowsInnerTheme}`}>
        <Grid item xs={12} md={6} className="image-wrap">
            {blocks[0]}
        </Grid>
        <Grid item xs={12} md={6} className="con-wrap">
            {blocks.map((block) => {
                return block;
            })}
        </Grid>
    </Grid>
};

export const CgbImageContentRowsInnerBlockFragment = graphql`
  fragment CgbImageContentRowsInnerBlock on WpBlock {
    ... on WpCgbImageContentRowsInnerBlock {
        name
        attributes {
            ... on WpCgbImageContentRowsInnerBlockAttributes {
                className
            }
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbImageContentRowsInnerBlockPreview = `
    ... on CgbImageContentRowsInnerBlock {
        name
        attributes {
            ... on CgbImageContentRowsInnerBlockAttributes {
                className
            }
        }
    }
`;