export default theme => ({
    socialSharing: {
        position: 'relative',
        marginBottom: 36,
        paddingTop: 32,
        paddingBottom: 24,
        '&::before': {
            content: '""',
            display: 'block',
            zIndex: -1,
            backgroundColor: theme.palette.background.secondary,
            top: 0,
            position: 'absolute',
            bottom: 0,
            left: '50%',
            width: '100vw',
            transform: 'translateX(-50vw)',
        },
        '& p.title': {
            fontFamily: theme.headerFont,
            fontSize: 18,
            marginBottom: 10,
            textTransform: 'uppercase',
        },
        '& p.link-list': {
            marginTop: 24,
            '& a': {
                marginRight: 40,
                color: theme.palette.primary.main,
                textDecoration: 'underline !important',
                '& span': {
                    verticalAlign: 'middle',
                    marginLeft: 22,
                },
                '& svg': {
                    width: 20,
                    height: 20,
                },
                '&:hover, &:focus': {
                    color: theme.palette.secondary.dark,
                }
            }
        },
        '& h2': {
            fontSize: 34,
            color: theme.palette.primary.main
        }
    },
    socialSharingTheme: {
        ...theme.socialSharingTheme,
    }
})