import {graphql} from "gatsby";
import {CoreParagraphBlockFragment} from "../components/blocks/CoreParagraphBlock/";  // eslint-disable-line
import {CoreHeadingBlockFragment} from "../components/blocks/CoreHeadingBlock";  // eslint-disable-line
import {CoreImageBlockFragment} from "../components/blocks/CoreImageBlock";  // eslint-disable-line
import {CoreColumnBlockFragment} from "../components/blocks/CoreColumnBlock";  // eslint-disable-line
import {CoreColumnsBlockFragment} from "../components/blocks/CoreColumnsBlock";  // eslint-disable-line
import {CoreButtonsBlockFragment} from "../components/blocks/CoreButtonsBlock";  // eslint-disable-line
import {CoreButtonBlockFragment} from "../components/blocks/CoreButtonBlock";  // eslint-disable-line
import {CoreGalleryBlockFragment} from "../components/blocks/CoreGalleryBlock";  // eslint-disable-line
import {CoreQuoteBlockFragment} from "../components/blocks/CoreQuoteBlock";  // eslint-disable-line
import {CoreListBlockFragment} from "../components/blocks/CoreListBlock";  // eslint-disable-line
import {CoreTableBlockFragment} from "../components/blocks/CoreTableBlock";  // eslint-disable-line
import {CoreFileBlockFragment} from "../components/blocks/CoreFileBlock";  // eslint-disable-line
import {CoreSpacerBlockFragment} from "../components/blocks/CoreSpacerBlock";  // eslint-disable-line
import {GravityformsFormBlockFragment} from "../components/blocks/GravityformsFormBlock";  // eslint-disable-line

import {CgbAccordionBlockFragment} from "../components/blocks/CgbAccordionBlock"; // eslint-disable-line
import {CgbAccordionInnerBlockFragment} from "../components/blocks/CgbAccordionInnerBlock"; // eslint-disable-line
import {CgbBannerCalloutCtaBlockFragment} from "../components/blocks/CgbBannerCalloutCtaBlock"; // eslint-disable-line
import {CgbBannerContentBlockFragment} from "../components/blocks/CgbBannerContentBlock"; // eslint-disable-line
import {CgbBannerMessageBlockFragment} from "../components/blocks/CgbBannerMessageBlock"; // eslint-disable-line
import {CgbBlogPagePostsBlockFragment} from "../components/blocks/CgbBlogPagePostsBlock"; // eslint-disable-line
import {CgbBlogPostBannerBlockFragment} from "../components/blocks/CgbBlogPostBannerBlock";  // eslint-disable-line
import {CgbBlogPostWrapBlockFragment} from "../components/blocks/CgbBlogPostWrapBlock";  // eslint-disable-line
import {CgbBreakoutGalleryIntroBlockFragment} from "../components/blocks/CgbBreakoutGalleryIntroBlock"; // eslint-disable-line
import {CgbCalendarAppBlockFragment} from "../components/blocks/CgbCalendarAppBlock"; // eslint-disable-line
import {CgbCalloutCtaBlockFragment} from "../components/blocks/CgbCalloutCtaBlock"; // eslint-disable-line
import {CgbCardBlockFragment} from "../components/blocks/CgbCardBlock" // eslint-disable-line
import {CgbCardColumnsBlockFragment} from "../components/blocks/CgbCardColumnsBlock" // eslint-disable-line
import {CgbCardRowBlockFragment} from "../components/blocks/CgbCardBlock" // eslint-disable-line
import {CgbContactCalloutBlockFragment} from "../components/blocks/CgbContactCalloutBlock" // eslint-disable-line
import {CgbContactFormBannerBlockFragment} from "../components/blocks/CgbContactFormBannerBlock" // eslint-disable-line
import {CgbContactPageIntroBlockFragment} from "../components/blocks/CgbContactPageIntroBlock" // eslint-disable-line
import {CgbFeaturedIconsBlockFragment} from "../components/blocks/CgbFeaturedIconsBlock"; // eslint-disable-line
import {CgbFeaturedIconsInnerBlockFragment} from "../components/blocks/CgbFeaturedIconsInnerBlock"; // eslint-disable-line
import {CgbFeaturedTextBlockFragment} from "../components/blocks/CgbFeaturedTextBlock"; // eslint-disable-line
import {CgbFileListBlockFragment} from "../components/blocks/CgbFileListBlock";  // eslint-disable-line
import {CgbFileListInnerBlockFragment} from "../components/blocks/CgbFileListInnerBlock";  // eslint-disable-line
import {CgbFileListInnerSmallBlockFragment} from "../components/blocks/CgbFileListInnerSmallBlock";  // eslint-disable-line
import {CgbGoodToGoBlockFragment} from "../components/blocks/CgbGoodToGoBlock";  // eslint-disable-line
import {CgbIconListIntroBlockFragment} from "../components/blocks/CgbIconListIntroBlock";  // eslint-disable-line
import {CgbIconListInnerBlockFragment} from "../components/blocks/CgbIconListInnerBlock";  // eslint-disable-line
import {CgbIconListBlockFragment} from "../components/blocks/CgbIconListBlock";  // eslint-disable-line
import {CgbImageBreakdownBlockFragment} from "../components/blocks/CgbImageBreakdownBlock";  // eslint-disable-line
import {CgbImageBreakdownInnerBlockFragment} from "../components/blocks/CgbImageBreakdownInnerBlock";  // eslint-disable-line
import {CgbImageContentRowsBlockFragment} from "../components/blocks/CgbImageContentRowsBlock";  // eslint-disable-line
import {CgbImageContentRowsInnerBlockFragment} from "../components/blocks/CgbImageContentRowsInnerBlock";  // eslint-disable-line
import {CgbImageGalleryGridBlockFragment} from "../components/blocks/CgbImageGalleryGridBlock";  // eslint-disable-line
import {CgbImageGalleryGridInnerLargeBlockFragment} from "../components/blocks/CgbImageGalleryGridInnerLargeBlock";  // eslint-disable-line
import {CgbImageGalleryGridInnerSmallBlockFragment} from "../components/blocks/CgbImageGalleryGridInnerSmallBlock";  // eslint-disable-line
import {CgbImageLinkRowBlockFragment} from "../components/blocks/CgbImageLinkRowBlock"; // eslint-disable-line
import {CgbImageLinkRowInnerBlockFragmentw} from "../components/blocks/CgbImageLinkRowInnerBlock"; // eslint-disable-line
import {CgbImageLinkSliderBlockFragment} from "../components/blocks/CgbImageLinkSliderBlock"; // eslint-disable-line
import {CgbImageLinkSliderInnerBlockFragment} from "../components/blocks/CgbImageLinkSliderInnerBlock"; // eslint-disable-line
import {CgbImageSliderBlockFragment} from "../components/blocks/CgbImageSliderBlock";  // eslint-disable-line
import {CgbIntroGalleryBlockFragment} from "../components/blocks/CgbIntroGalleryBlock";  // eslint-disable-line
import {CgbIntroGallerySplitBlockFragment} from "../components/blocks/CgbIntroGallerySplitBlock";  // eslint-disable-line
import {CgbIntroImageSliderBlockFragment} from "../components/blocks/CgbIntroImageSliderBlock";  // eslint-disable-line
import {CgbLatestPostsBlockFragment} from "../components/blocks/CgbLatestPostsBlock";  // eslint-disable-line
import {CgbLatestPostsGridBlockFragment} from "../components/blocks/CgbLatestPostsGridBlock";  // eslint-disable-line
import {CgbLandingVideoBlockFragment} from "../components/blocks/CgbLandingVideoBlock"; // eslint-disable-line
import {CgbNextPreviousPostsBlockFragment} from "../components/blocks/CgbNextPreviousPostsBlock";  // eslint-disable-line
import {CgbOfferGridBlockFragment} from "../components/blocks/CgbOfferGridBlock";  // eslint-disable-line
import {CgbOfferGridInnerBlockFragment} from "../components/blocks/CgbOfferGridInnerBlock";  // eslint-disable-line
import {CgbPageTitleBlockFragment} from "../components/blocks/CgbPageTitleBlock";  // eslint-disable-line
import {CgbPullquoteBlockFragment} from "../components/blocks/CgbPullquoteBlock";  // eslint-disable-line
import {CgbSeparatorBlockFragment} from "../components/blocks/CgbSeparatorBlock"; // eslint-disable-line
import {CgbSocialSharingBlockFragment} from "../components/blocks/CgbSocialSharingBlock"; // eslint-disable-line
import {CgbTabContentBlockFragment} from "../components/blocks/CgbTabContentBlock";  // eslint-disable-line
import {CgbTabContentInnerBlockFragment} from "../components/blocks/CgbTabContentInnerBlock";  // eslint-disable-line
import {CgbTestimonialBlockFragment} from "../components/blocks/CgbTestimonialBlock";  // eslint-disable-line
import {CgbThickIntroBlockFragment} from "../components/blocks/CgbThickIntroBlock";  // eslint-disable-line
import {CgbThinIntroBlockFragment} from "../components/blocks/CgbThinIntroBlock";  // eslint-disable-line
import {CgbTwoCardIntroBlockFragment} from "../components/blocks/CgbTwoCardIntroBlock";  // eslint-disable-line
import {CgbTwoCardSplitBlockFragment} from "../components/blocks/CgbTwoCardSplitBlock"; // eslint-disable-line
import {CgbTwoColIntroBlockFragment} from "../components/blocks/CgbTwoColIntroBlock";  // eslint-disable-line
import {CgbTwoColWrapperBlockFragment} from "../components/blocks/CgbTwoColWrapperBlock"; // eslint-disable-line
import {CgbTwoColInnerBlockFragment} from "../components/blocks/CgbTwoColInnerBlock"; // eslint-disable-line
import {CgbVideoBannerMessageBlockFragment} from "../components/blocks/CgbVideoBannerMessageBlock";  // eslint-disable-line
import {CgbVideoEmbedBlockFragment} from "../components/blocks/CgbVideoEmbedBlock"; // eslint-disable-line
import {CgbVideoTestimonialBlockFragment} from "../components/blocks/CgbVideoTestimonialBlock"; // eslint-disable-line

import {CoreParagraphBlockPreview} from "../components/blocks/CoreParagraphBlock/";  // eslint-disable-line
import {CoreHeadingBlockPreview} from "../components/blocks/CoreHeadingBlock/";  // eslint-disable-line
import {CoreColumnBlockPreview} from "../components/blocks/CoreColumnBlock/";  // eslint-disable-line
import {CoreColumnsBlockPreview} from "../components/blocks/CoreColumnsBlock/";  // eslint-disable-line
import {CoreImageBlockPreview} from "../components/blocks/CoreImageBlock/";  // eslint-disable-line
import {CoreButtonsBlockPreview} from "../components/blocks/CoreButtonsBlock/";  // eslint-disable-line
import {CoreButtonBlockPreview} from "../components/blocks/CoreButtonBlock/";  // eslint-disable-line
import {CoreGalleryBlockPreview} from "../components/blocks/CoreGalleryBlock/";  // eslint-disable-line
import {CoreQuoteBlockPreview} from "../components/blocks/CoreQuoteBlock/";  // eslint-disable-line
import {CoreListBlockPreview} from "../components/blocks/CoreListBlock/";  // eslint-disable-lined
import {CoreTableBlockPreview} from "../components/blocks/CoreTableBlock/";  // eslint-disable-lined
import {CoreFileBlockPreview} from "../components/blocks/CoreFileBlock/";  // eslint-disable-lined
import {CoreSpacerBlockPreview} from "../components/blocks/CoreSpacerBlock/";  // eslint-disable-lined
import {GravityformsFormBlockPreview} from "../components/blocks/GravityformsFormBlock";  // eslint-disable-line
import {CgbAccordionBlockPreview} from "../components/blocks/CgbAccordionBlock"; // eslint-disable-line
import {CgbAccordionInnerBlockPreview} from "../components/blocks/CgbAccordionInnerBlock"; // eslint-disable-line
import {CgbBannerCalloutCtaBlockPreview} from "../components/blocks/CgbBannerCalloutCtaBlock"; // eslint-disable-line
import {CgbBannerContentBlockPreview} from "../components/blocks/CgbBannerContentBlock"; // eslint-disable-line
import {CgbBannerMessageBlockPreview} from "../components/blocks/CgbBannerMessageBlock"; // eslint-disable-line
import {CgbBlogPagePostsBlockPreview} from "../components/blocks/CgbBlogPagePostsBlock"; // eslint-disable-line
import {CgbBlogPostBannerBlockPreview} from "../components/blocks/CgbBlogPostBannerBlock";  // eslint-disable-line
import {CgbBlogPostWrapBlockPreview} from "../components/blocks/CgbBlogPostWrapBlock";  // eslint-disable-line
import {CgbBreakoutGalleryIntroBlockPreview} from "../components/blocks/CgbBreakoutGalleryIntroBlock"; // eslint-disable-line
import {CgbCalendarAppBlockPreview} from "../components/blocks/CgbCalendarAppBlock"; // eslint-disable-line
import {CgbCalloutCtaBlockPreview} from "../components/blocks/CgbCalloutCtaBlock"; // eslint-disable-line
import {CgbCardBlockPreview} from "../components/blocks/CgbCardBlock" // eslint-disable-line
import {CgbCardColumnsBlockPreview} from "../components/blocks/CgbCardColumnsBlock" // eslint-disable-line
import {CgbCardRowBlockPreview} from "../components/blocks/CgbCardRowBlock" // eslint-disable-line
import {CgbContactCalloutBlockPreview} from "../components/blocks/CgbContactCalloutBlock" // eslint-disable-line
import {CgbContactFormBannerBlockPreview} from "../components/blocks/CgbContactFormBannerBlock" // eslint-disable-line
import {CgbContactPageIntroBlockPreview} from "../components/blocks/CgbContactPageIntroBlock" // eslint-disable-line
import {CgbFeaturedIconsBlockPreview} from "../components/blocks/CgbFeaturedIconsBlock"; // eslint-disable-line
import {CgbFeaturedIconsInnerBlockPreview} from "../components/blocks/CgbFeaturedIconsInnerBlock"; // eslint-disable-line
import {CgbFeaturedTextBlockPreview} from "../components/blocks/CgbFeaturedTextBlock"; // eslint-disable-line
import {CgbFileListBlockPreview} from "../components/blocks/CgbFileListBlock";  // eslint-disable-line
import {CgbFileListInnerBlockPreview} from "../components/blocks/CgbFileListInnerBlock";  // eslint-disable-line
import {CgbFileListInnerSmallBlockPreview} from "../components/blocks/CgbFileListInnerSmallBlock";  // eslint-disable-line
import {CgbGoodToGoBlockPreview} from "../components/blocks/CgbGoodToGoBlock";  // eslint-disable-line
import {CgbIconListIntroBlockPreview} from "../components/blocks/CgbIconListIntroBlock";  // eslint-disable-line
import {CgbIconListInnerBlockPreview} from "../components/blocks/CgbIconListInnerBlock";  // eslint-disable-line
import {CgbIconListBlockPreview} from "../components/blocks/CgbIconListBlock";  // eslint-disable-line
import {CgbImageBreakdownBlockPreview} from "../components/blocks/CgbImageBreakdownBlock";  // eslint-disable-line
import {CgbImageBreakdownInnerBlockPreview} from "../components/blocks/CgbImageBreakdownInnerBlock";  // eslint-disable-line
import {CgbImageContentRowsBlockPreview} from "../components/blocks/CgbImageContentRowsBlock";  // eslint-disable-line
import {CgbImageContentRowsInnerBlockPreview} from "../components/blocks/CgbImageContentRowsInnerBlock";  // eslint-disable-line
import {CgbImageGalleryGridBlockPreview} from "../components/blocks/CgbImageGalleryGridBlock";  // eslint-disable-line
import {CgbImageGalleryGridInnerLargeBlockPreview} from "../components/blocks/CgbImageGalleryGridInnerLargeBlock";  // eslint-disable-line
import {CgbImageGalleryGridInnerSmallBlockPreview} from "../components/blocks/CgbImageGalleryGridInnerSmallBlock";  // eslint-disable-line
import {CgbImageLinkRowBlockPreview} from "../components/blocks/CgbImageLinkRowBlock"; // eslint-disable-line
import {CgbImageLinkRowInnerBlockPreview} from "../components/blocks/CgbImageLinkRowInnerBlock"; // eslint-disable-line
import {CgbImageLinkSliderBlockPreview} from "../components/blocks/CgbImageLinkSliderBlock"; // eslint-disable-line
import {CgbImageLinkSliderInnerBlockPreview} from "../components/blocks/CgbImageLinkSliderInnerBlock"; // eslint-disable-line
import {CgbImageSliderBlockPreview} from "../components/blocks/CgbImageSliderBlock";  // eslint-disable-line
import {CgbIntroGalleryBlockPreview} from "../components/blocks/CgbIntroGalleryBlock";  // eslint-disable-line
import {CgbIntroGallerySplitBlockPreview} from "../components/blocks/CgbIntroGallerySplitBlock";  // eslint-disable-line
import {CgbIntroImageSliderBlockPreview} from "../components/blocks/CgbIntroImageSliderBlock";  // eslint-disable-line
import {CgbLandingVideoBlockPreview} from "../components/blocks/CgbLandingVideoBlock"; // eslint-disable-line
import {CgbLatestPostsBlockPreview} from "../components/blocks/CgbLatestPostsBlock";  // eslint-disable-line
import {CgbLatestPostsGridBlockPreview} from "../components/blocks/CgbLatestPostsGridBlock";  // eslint-disable-line
import {CgbNextPreviousPostsBlockPreview} from "../components/blocks/CgbNextPreviousPostsBlock";  // eslint-disable-line
import {CgbOfferGridBlockPreview} from "../components/blocks/CgbOfferGridBlock";  // eslint-disable-line
import {CgbOfferGridInnerBlockPreview} from "../components/blocks/CgbOfferGridInnerBlock";  // eslint-disable-line
import {CgbPageTitleBlockPreview} from "../components/blocks/CgbPageTitleBlock";  // eslint-disable-line
import {CgbPullquoteBlockPreview} from "../components/blocks/CgbPullquoteBlock";  // eslint-disable-line
import {CgbSeparatorBlockPreview} from "../components/blocks/CgbSeparatorBlock"; // eslint-disable-line
import {CgbSocialSharingBlockPreview} from "../components/blocks/CgbSocialSharingBlock"; // eslint-disable-line
import {CgbTabContentBlockPreview} from "../components/blocks/CgbTabContentBlock";  // eslint-disable-line
import {CgbTabContentInnerBlockPreview} from "../components/blocks/CgbTabContentInnerBlock";  // eslint-disable-line
import {CgbTestimonialBlockPreview} from "../components/blocks/CgbTestimonialBlock";  // eslint-disable-line
import {CgbThickIntroBlockPreview} from "../components/blocks/CgbThickIntroBlock";  // eslint-disable-line
import {CgbThinIntroBlockPreview} from "../components/blocks/CgbThinIntroBlock";  // eslint-disable-line
import {CgbTwoCardIntroBlockPreview} from "../components/blocks/CgbTwoCardIntroBlock";  // eslint-disable-line
import {CgbTwoCardSplitBlockPreview} from "../components/blocks/CgbTwoCardSplitBlock"; // eslint-disable-line
import {CgbTwoColIntroBlockPreview} from "../components/blocks/CgbTwoColIntroBlock";  // eslint-disable-line
import {CgbTwoColWrapperBlockPreview} from "../components/blocks/CgbTwoColWrapperBlock"; // eslint-disable-line
import {CgbTwoColInnerBlockPreview} from "../components/blocks/CgbTwoColInnerBlock"; // eslint-disable-line
import {CgbVideoBannerMessageBlockPreview} from "../components/blocks/CgbVideoBannerMessageBlock";  // eslint-disable-line
import {CgbVideoEmbedBlockPreview} from "../components/blocks/CgbVideoEmbedBlock"; // eslint-disable-line
import {CgbVideoTestimonialBlockPreview} from "../components/blocks/CgbVideoTestimonialBlock"; // eslint-disable-line

export const CoreFields = graphql`
fragment CoreFields on WpBlock {
	name
    ...CoreParagraphBlock
    ...CoreHeadingBlock
    ...CoreImageBlock
    ...CoreColumnBlock
    ...CoreColumnsBlock
    ...CoreButtonsBlock
    ...CoreButtonBlock
    ...CoreGalleryBlock
    ...CoreQuoteBlock
    ...CoreListBlock
    ...CoreTableBlock
    ...CoreFileBlock
    ...CoreSpacerBlock
    ...GravityformsFormBlock   
    ...CgbAccordionBlock
    ...CgbAccordionInnerBlock 
    ...CgbBannerCalloutCtaBlock
    ...CgbBannerContentBlock
    ...CgbBannerMessageBlock
    ...CgbBreakoutGalleryIntroBlock
    ...CgbBlogPagePostsBlock
    ...CgbBlogPostBannerBlock
    ...CgbBlogPostWrapBlock
    ...CgbCalendarAppBlock
    ...CgbCalloutCtaBlock
    ...CgbCardBlock
    ...CgbCardColumnsBlock
    ...CgbCardRowBlock
    ...CgbContactCalloutBlock
    ...CgbContactFormBannerBlock
    ...CgbContactPageIntroBlock
    ...CgbFeaturedIconsBlock
    ...CgbFeaturedIconsInnerBlock
    ...CgbFeaturedTextBlock
    ...CgbFileListBlock
    ...CgbFileListInnerBlock
    ...CgbFileListInnerSmallBlock
    ...CgbGoodToGoBlock
    ...CgbIconListIntroBlock
    ...CgbIconListInnerBlock
    ...CgbIconListBlock
    ...CgbImageBreakdownBlock
    ...CgbImageBreakdownInnerBlock
    ...CgbImageContentRowsBlock
    ...CgbImageContentRowsInnerBlock
    ...CgbImageGalleryGridBlock
    ...CgbImageGalleryGridInnerLargeBlock
    ...CgbImageGalleryGridInnerSmallBlock
    ...CgbImageLinkRowBlock
    ...CgbImageLinkRowInnerBlock
    ...CgbImageLinkSliderBlock
    ...CgbImageLinkSliderInnerBlock
    ...CgbImageSliderBlock
    ...CgbIntroGalleryBlock
    ...CgbIntroGallerySplitBlock
    ...CgbIntroImageSliderBlock
    ...CgbLandingVideoBlock
    ...CgbLatestPostsBlock
    ...CgbLatestPostsGridBlock
    ...CgbNextPreviousPostsBlock
    ...CgbOfferGridBlock
    ...CgbOfferGridInnerBlock
    ...CgbPageTitleBlock
    ...CgbPullquoteBlock
    ...CgbSeparatorBlock
    ...CgbSocialSharingBlock
    ...CgbTabContentBlock
    ...CgbTabContentInnerBlock
    ...CgbTestimonialBlock
    ...CgbThickIntroBlock
    ...CgbThinIntroBlock
    ...CgbTwoCardIntroBlock
    ...CgbTwoCardSplitBlock
    ...CgbTwoColIntroBlock
    ...CgbTwoColWrapperBlock
    ...CgbTwoColInnerBlock
    ...CgbVideoBannerMessageBlock
    ...CgbVideoEmbedBlock
    ...CgbVideoTestimonialBlock
	innerBlocks {
		name
        ...CoreParagraphBlock
        ...CoreHeadingBlock
        ...CoreImageBlock
        ...CoreColumnBlock
        ...CoreColumnsBlock
        ...CoreButtonsBlock
        ...CoreButtonBlock
        ...CoreGalleryBlock
        ...CoreQuoteBlock
        ...CoreListBlock
        ...CoreTableBlock
        ...CoreFileBlock
        ...CoreSpacerBlock
        ...GravityformsFormBlock   
        ...CgbAccordionBlock
        ...CgbAccordionInnerBlock 
        ...CgbBannerCalloutCtaBlock
        ...CgbBannerContentBlock
        ...CgbBannerMessageBlock
        ...CgbBlogPagePostsBlock
        ...CgbBlogPostBannerBlock
        ...CgbBlogPostWrapBlock
        ...CgbBreakoutGalleryIntroBlock
        ...CgbCalloutCtaBlock
        ...CgbCardBlock
        ...CgbCardColumnsBlock
        ...CgbCardRowBlock
        ...CgbContactCalloutBlock
        ...CgbContactFormBannerBlock
        ...CgbContactPageIntroBlock
        ...CgbFeaturedIconsBlock
        ...CgbFeaturedIconsInnerBlock
        ...CgbFeaturedTextBlock
        ...CgbFileListBlock
        ...CgbFileListInnerBlock
        ...CgbFileListInnerSmallBlock
        ...CgbGoodToGoBlock
        ...CgbIconListIntroBlock
        ...CgbIconListInnerBlock
        ...CgbIconListBlock
        ...CgbImageBreakdownBlock
        ...CgbImageBreakdownInnerBlock
        ...CgbImageContentRowsBlock
        ...CgbImageContentRowsInnerBlock
        ...CgbImageGalleryGridBlock
        ...CgbImageGalleryGridInnerLargeBlock
        ...CgbImageGalleryGridInnerSmallBlock
        ...CgbImageLinkRowBlock
        ...CgbImageLinkRowInnerBlock
        ...CgbImageLinkSliderBlock
        ...CgbImageLinkSliderInnerBlock
        ...CgbImageSliderBlock
        ...CgbIntroGalleryBlock
        ...CgbIntroGallerySplitBlock
        ...CgbIntroImageSliderBlock
        ...CgbLandingVideoBlock
        ...CgbLatestPostsBlock
        ...CgbLatestPostsGridBlock
        ...CgbNextPreviousPostsBlock
        ...CgbOfferGridBlock
        ...CgbOfferGridInnerBlock
        ...CgbPageTitleBlock
        ...CgbPullquoteBlock
        ...CgbSeparatorBlock
        ...CgbSocialSharingBlock
        ...CgbTabContentBlock
        ...CgbTabContentInnerBlock
        ...CgbTestimonialBlock
        ...CgbThickIntroBlock
        ...CgbThinIntroBlock
        ...CgbTwoCardIntroBlock
        ...CgbTwoCardSplitBlock
        ...CgbTwoColIntroBlock
        ...CgbTwoColWrapperBlock
        ...CgbTwoColInnerBlock
        ...CgbVideoBannerMessageBlock
        ...CgbVideoEmbedBlock
        ...CgbVideoTestimonialBlock
		innerBlocks {
			name
            ...CoreParagraphBlock
            ...CoreHeadingBlock
            ...CoreImageBlock
            ...CoreColumnBlock
            ...CoreColumnsBlock
            ...CoreButtonsBlock
            ...CoreButtonBlock
            ...CoreGalleryBlock
            ...CoreQuoteBlock
            ...CoreListBlock
            ...CoreTableBlock
            ...CoreFileBlock
            ...CoreSpacerBlock
            ...GravityformsFormBlock   
            ...CgbAccordionBlock
            ...CgbAccordionInnerBlock 
            ...CgbBannerCalloutCtaBlock
            ...CgbBannerContentBlock
            ...CgbBannerMessageBlock
            ...CgbBlogPagePostsBlock
            ...CgbBlogPostBannerBlock
            ...CgbBlogPostWrapBlock
            ...CgbBreakoutGalleryIntroBlock
            ...CgbCalloutCtaBlock
            ...CgbContactFormBannerBlock
            ...CgbCardBlock
            ...CgbCardColumnsBlock
            ...CgbCardRowBlock
            ...CgbContactCalloutBlock
            ...CgbContactPageIntroBlock
            ...CgbFeaturedIconsBlock
            ...CgbFeaturedIconsInnerBlock
            ...CgbFeaturedTextBlock
            ...CgbFileListBlock
            ...CgbFileListInnerBlock
            ...CgbFileListInnerSmallBlock
            ...CgbGoodToGoBlock
            ...CgbIconListIntroBlock
            ...CgbIconListInnerBlock
            ...CgbIconListBlock
            ...CgbImageBreakdownBlock
            ...CgbImageBreakdownInnerBlock
            ...CgbImageContentRowsBlock
            ...CgbImageContentRowsInnerBlock
            ...CgbImageGalleryGridBlock
            ...CgbImageGalleryGridInnerLargeBlock
            ...CgbImageGalleryGridInnerSmallBlock
            ...CgbImageLinkRowBlock
            ...CgbImageLinkRowInnerBlock
            ...CgbImageLinkSliderBlock
            ...CgbImageLinkSliderInnerBlock
            ...CgbImageSliderBlock
            ...CgbIntroGalleryBlock
            ...CgbIntroGallerySplitBlock
            ...CgbIntroImageSliderBlock
            ...CgbLandingVideoBlock
            ...CgbLatestPostsBlock
            ...CgbLatestPostsGridBlock
            ...CgbNextPreviousPostsBlock
            ...CgbOfferGridBlock
            ...CgbOfferGridInnerBlock
            ...CgbPageTitleBlock
            ...CgbPullquoteBlock
            ...CgbSeparatorBlock
            ...CgbSocialSharingBlock
            ...CgbTabContentBlock
            ...CgbTabContentInnerBlock
            ...CgbTestimonialBlock
            ...CgbThickIntroBlock
            ...CgbThinIntroBlock
            ...CgbTwoCardIntroBlock
            ...CgbTwoCardSplitBlock
            ...CgbTwoColIntroBlock
            ...CgbTwoColWrapperBlock
            ...CgbTwoColInnerBlock
            ...CgbVideoBannerMessageBlock
            ...CgbVideoEmbedBlock
            ...CgbVideoTestimonialBlock
			innerBlocks {
				name
                ...CoreParagraphBlock
                ...CoreHeadingBlock
                ...CoreImageBlock
                ...CoreColumnBlock
                ...CoreColumnsBlock
                ...CoreButtonsBlock
                ...CoreButtonBlock
                ...CoreGalleryBlock
                ...CoreQuoteBlock
                ...CoreListBlock
                ...CoreTableBlock
                ...CoreFileBlock
                ...CoreSpacerBlock
                ...GravityformsFormBlock   
                ...CgbAccordionBlock
                ...CgbAccordionInnerBlock 
                ...CgbBannerCalloutCtaBlock
                ...CgbBannerContentBlock
                ...CgbBannerMessageBlock
                ...CgbBlogPagePostsBlock
                ...CgbBlogPostWrapBlock
                ...CgbBreakoutGalleryIntroBlock
                ...CgbCalloutCtaBlock
                ...CgbCardBlock
                ...CgbCardRowBlock
                ...CgbContactCalloutBlock
                ...CgbContactFormBannerBlock
                ...CgbContactPageIntroBlock
                ...CgbFeaturedIconsBlock
                ...CgbFeaturedIconsInnerBlock
                ...CgbFeaturedTextBlock
                ...CgbFileListBlock
                ...CgbFileListInnerBlock
                ...CgbFileListInnerSmallBlock
                ...CgbGoodToGoBlock
                ...CgbIconListIntroBlock
                ...CgbIconListInnerBlock
                ...CgbIconListBlock
                ...CgbImageBreakdownBlock
                ...CgbImageBreakdownInnerBlock
                ...CgbImageContentRowsBlock
                ...CgbImageContentRowsInnerBlock
                ...CgbImageGalleryGridBlock
                ...CgbImageGalleryGridInnerLargeBlock
                ...CgbImageGalleryGridInnerSmallBlock
                ...CgbImageLinkRowBlock
                ...CgbImageLinkRowInnerBlock
                ...CgbImageLinkSliderBlock
                ...CgbImageLinkSliderInnerBlock
                ...CgbImageSliderBlock
                ...CgbIntroGalleryBlock
                ...CgbIntroGallerySplitBlock
                ...CgbIntroImageSliderBlock
                ...CgbLandingVideoBlock
                ...CgbLatestPostsBlock
                ...CgbLatestPostsGridBlock
                ...CgbNextPreviousPostsBlock
                ...CgbOfferGridBlock
                ...CgbOfferGridInnerBlock
                ...CgbPageTitleBlock
                ...CgbPullquoteBlock
                ...CgbSeparatorBlock
                ...CgbSocialSharingBlock
                ...CgbTabContentBlock
                ...CgbTabContentInnerBlock
                ...CgbTestimonialBlock
                ...CgbThickIntroBlock
                ...CgbThinIntroBlock
                ...CgbTwoCardIntroBlock
                ...CgbTwoCardSplitBlock
                ...CgbTwoColIntroBlock
                ...CgbTwoColWrapperBlock
                ...CgbTwoColInnerBlock
                ...CgbVideoBannerMessageBlock
                ...CgbVideoEmbedBlock
                ...CgbVideoTestimonialBlock
			}
		}
	}
}
`;

export const PreviewBlocks = `
  ${CoreParagraphBlockPreview}
  ${CoreHeadingBlockPreview}
  ${CoreColumnBlockPreview}
  ${CoreColumnsBlockPreview}
  ${CoreImageBlockPreview}
  ${CoreButtonsBlockPreview}
  ${CoreButtonBlockPreview}
  ${CoreGalleryBlockPreview}
  ${CoreGalleryBlockPreview}
  ${CoreQuoteBlockPreview}
  ${CoreListBlockPreview}
  ${CoreTableBlockPreview}
  ${CoreFileBlockPreview}
  ${CoreSpacerBlockPreview}
  ${GravityformsFormBlockPreview}

  ${CoreParagraphBlockPreview}
  ${CoreHeadingBlockPreview}
  ${CoreImageBlockPreview}
  ${CoreColumnBlockPreview}
  ${CoreColumnsBlockPreview}
  ${CoreButtonsBlockPreview}
  ${CoreButtonBlockPreview}
  ${CoreGalleryBlockPreview}
  ${CoreQuoteBlockPreview}
  ${CoreListBlockPreview}
  ${CoreTableBlockPreview}
  ${CoreFileBlockPreview}
  ${CoreSpacerBlockPreview}
  ${GravityformsFormBlockPreview}
  ${CgbAccordionBlockPreview}
  ${CgbAccordionInnerBlockPreview}
  ${CgbBannerCalloutCtaBlockPreview}
  ${CgbBannerContentBlockPreview}
  ${CgbBannerMessageBlockPreview}
  ${CgbBlogPagePostsBlockPreview}
  ${CgbBlogPostBannerBlockPreview}
  ${CgbBlogPostWrapBlockPreview}
  ${CgbBreakoutGalleryIntroBlockPreview}
  ${CgbCalendarAppBlockPreview}
  ${CgbCalloutCtaBlockPreview}
  ${CgbCardBlockPreview}
  ${CgbCardColumnsBlockPreview}
  ${CgbCardRowBlockPreview}
  ${CgbContactCalloutBlockPreview}
  ${CgbContactFormBannerBlockPreview}
  ${CgbContactPageIntroBlockPreview}
  ${CgbFeaturedIconsBlockPreview}
  ${CgbFeaturedIconsInnerBlockPreview}
  ${CgbFeaturedTextBlockPreview}
  ${CgbFileListBlockPreview}
  ${CgbFileListInnerBlockPreview}
  ${CgbFileListInnerSmallBlockPreview}
  ${CgbGoodToGoBlockPreview}
  ${CgbIconListIntroBlockPreview}
  ${CgbIconListInnerBlockPreview}
  ${CgbIconListBlockPreview}
  ${CgbImageBreakdownBlockPreview}
  ${CgbImageBreakdownInnerBlockPreview}
  ${CgbImageContentRowsBlockPreview}
  ${CgbImageContentRowsInnerBlockPreview}
  ${CgbImageGalleryGridBlockPreview}
  ${CgbImageGalleryGridInnerLargeBlockPreview}
  ${CgbImageGalleryGridInnerSmallBlockPreview}
  ${CgbImageLinkRowBlockPreview}
  ${CgbImageLinkRowInnerBlockPreview}
  ${CgbImageLinkSliderBlockPreview}
  ${CgbImageLinkSliderInnerBlockPreview}
  ${CgbImageSliderBlockPreview}
  ${CgbIntroGalleryBlockPreview}
  ${CgbIntroGallerySplitBlockPreview}
  ${CgbIntroImageSliderBlockPreview}
  ${CgbLandingVideoBlockPreview}
  ${CgbLatestPostsBlockPreview}
  ${CgbLatestPostsGridBlockPreview}
  ${CgbNextPreviousPostsBlockPreview}
  ${CgbOfferGridBlockPreview}
  ${CgbOfferGridInnerBlockPreview}
  ${CgbPageTitleBlockPreview}
  ${CgbPullquoteBlockPreview}
  ${CgbSeparatorBlockPreview}
  ${CgbSocialSharingBlockPreview}
  ${CgbTabContentBlockPreview}
  ${CgbTabContentInnerBlockPreview}
  ${CgbTestimonialBlockPreview}
  ${CgbThickIntroBlockPreview}
  ${CgbThinIntroBlockPreview}
  ${CgbTwoCardIntroBlockPreview}
  ${CgbTwoCardSplitBlockPreview}
  ${CgbTwoColIntroBlockPreview}
  ${CgbTwoColWrapperBlockPreview}
  ${CgbTwoColInnerBlockPreview}
  ${CgbVideoBannerMessageBlockPreview}
  ${CgbVideoEmbedBlockPreview}
  ${CgbVideoTestimonialBlockPreview}
  `;

