export default theme => ({
    bannerMessage: {
        position: 'relative',
        marginBottom: 36,
        fontSize: 0,
        '&.overlay': {
            '&::before': {
                content: '""',
                backgroundColor: theme.palette.primary.main,
                top: 0,
                bottom: 0,
                width: '100vw',
                left: '50%',
                transform: 'translateX(-50%)',
                zIndex: 1,
                position: 'absolute',
                opacity: .5,
            },
        },
        '& .desktop-image': {
            display: 'none !important',
            [theme.breakpoints.up('md')]: {
                display: 'block !important',
            },
        },
        '& .mobile-image': {
            display: 'block !important',
        },
        [theme.breakpoints.up('md')]: {
            '& .mobile-image': {
                display: 'none !important',
            }
        },
        '& > .gatsby-image-wrapper': {
            height: '595px !important',
            marginLeft: '50%',
            transform: 'translateX(-50%)',
            maxWidth: '100vw !important',
            width: 'calc(100% + 50px) !important',
            zIndex: '0',
            [theme.breakpoints.up('md')]: {
                height: 'auto !important',
                width: '100vw !important',
                '& > div': {
                    paddingBottom: '50% !important',
                }
            },
            '&::after': {
                position: 'absolute',
                display: 'block',
                content: '""',
                bottom: '0',
                left: '0',
                right: '0',
                height: 300,
                zIndex: '2',
                background: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 45%,rgba(0,0,0,1) 100%)',
                opacity: '0.3',
                [theme.breakpoints.up('md')]: {
                    height: 340,
                    opacity: '0.51',
                    background: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.58) 33%,rgba(0,0,0,1) 66%,rgba(0,0,0,1) 100%)',
                },
            },
        },
        '& h1, & h2, & h3, & h4, & h5, & h6' : {
            position: 'absolute',
            bottom: 90,
            color: 'white',
            marginBottom: '0',
            zIndex: '3',
            [theme.breakpoints.up('sm')]: {
                maxWidth: '85%'
            },
            [theme.breakpoints.up('md')]: {
                bottom: 50,
            },
        },
        '&.left-top .gatsby-image-wrapper img': {
            objectPosition: 'left top !important',
        },
        '&.left-center .gatsby-image-wrapper img': {
            objectPosition: 'left center !important',
        },
        '&.left-bottom .gatsby-image-wrapper img': {
            objectPosition: 'left bottom !important',
        },
        '&.center-top .gatsby-image-wrapper img': {
            objectPosition: 'center top !important',
        },
        '&.center-bottom .gatsby-image-wrapper img': {
            objectPosition: 'center bottom !important',
        },
        '&.right-top .gatsby-image-wrapper img': {
            objectPosition: 'right top !important',
        },
        '&.right-center .gatsby-image-wrapper img': {
            objectPosition: 'right center !important',
        },
        '&.right-bottom .gatsby-image-wrapper img': {
            objectPosition: 'right bottom !important',
        },
    },
    bannerMessageTheme: {
        ...theme.bannerMessageTheme,
    }
});