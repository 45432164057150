export default theme => ({
    imageContentRowsInner: {
        position: 'relative',
        backgroundColor: theme.palette.secondary.main,
        marginBottom: 0,
        '& .gatsby-image-wrapper': {
            height: 'auto !important',
            [theme.breakpoints.up('md')]: {
                '& > div': {
                    paddingBottom: '0 !important',
                }
            },
        },
        [theme.breakpoints.up('md')]: {
            margin: '0 0 0 50%',
            width: '100vw',
            padding: '75px 0',
            transform: 'translateX(-50vw)',
            '& .gatsby-image-wrapper': {
                maxWidth: 'calc(100% - 12px)',
            }
        },
        '& h1, & h2, & h3, & h4, & h5, & h6, & p, & a': {
            color: theme.palette.secondary.contrastText,
        },
        '& a': {
            textDecoration: 'underline',
            '&:hover, &:focus': {
                opacity: '0.9',
            }
        },
        '& .con-wrap': {
            position: 'relative',
            zIndex: '2',
            padding: 24,
            [theme.breakpoints.up('md')]: {
                padding: 50,
                position: 'initial',
            },
            '& h1, & h2, & h3, & h4, & h5, & h6': {
                fontWeight: '400',
                marginBottom: 20,
                fontSize: 32,
            },
            '& p': {
                marginTop: 20,
                marginBottom: 20,
            },
            '& .gatsby-image-wrapper': {
                display: 'none !important',
            }
        },
        '&:nth-of-type(odd)': {
            [theme.breakpoints.up('md')]: {
                flexDirection: 'row-reverse',
                '& .gatsby-image-wrapper': {
                    marginLeft: 'auto',
                },
            }
        }
    },
    imageContentRowsInnerTheme: {
        ...theme.imageContentRowsInnerTheme
    },
})