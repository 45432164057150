import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import { buildBlocks } from "../../../services/builder";
import Img from "gatsby-image";
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CgbBreakoutGalleryIntroBlock = (props) => {
    // console.log(props);
    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();
    // if( ! props.attributes.mediaUrl) return null;
    let image = null;

    // Use the gatsby image if available
    // if(props.attributes.mediaUrlSharp){
    //     if(props.attributes.mediaUrlSharp.childImageSharp){
    //         image = <Img
    //             fluid={props.attributes.mediaUrlSharp.childImageSharp.fluid}
    //             alt=""
    //         />
    //     } else {
    //         // Gifs do not have a childImageSharp
    //         image = <img
    //             src={props.attributes.mediaUrlSharp.publicURL}
    //             alt=""
    //         />
    //     }
    // } else {
        // This is a preview image
        image = <div className="gatsby-image-wrapper">
            <div style={{paddingBottom: '55%'}} />
            <img
                src={props.attributes.mediaUrl.charAt(0) == '/' ? 'https://brew-platform.staging.brew-systems.co.uk/' + props.attributes.mediaUrl : props.attributes.mediaUrl}
                alt=""
                style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center' }}
            />
        </div>
    // }

    return <div
        className={`breakout-gallery-intro ${props.attributes.className ? props.attributes.className : ''} ${classes.breakoutGalleryIntro} ${classes.bannerMessageTheme} ${classes.breakoutGalleryIntroTheme}`}
        data-header-bg={props.attributes.background}
    >
        <div className="image-wrapper">
            {image}
        </div>
        <p className="micro-header">{props.attributes.tag}</p>
        <div className="content-wrap">
            <div className="bold-con">
                {blocks[0]}
            </div>
            <div className="standard-con">
                {blocks[1]}
            </div>
            <div className="gallery">
                {blocks[2]}
            </div>
        </div>
    </div>
};

CgbBreakoutGalleryIntroBlock.propTypes = {
    attributes: PropTypes.object.isRequired,
};

export const CgbBreakoutGalleryIntroBlockFragment = graphql`
    fragment CgbBreakoutGalleryIntroBlock on WpBlock {
        ... on WpCgbBreakoutGalleryIntroBlock {
            name
            attributes {
                __typename
                ... on WpCgbBreakoutGalleryIntroBlockAttributes {
                    className
                    tag
                    mediaUrl  
                }
            }
        }
    }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbBreakoutGalleryIntroBlockPreview = `
    ... on CgbBreakoutGalleryIntroBlock {
        name
        attributes {
            __typename
            ... on CgbBreakoutGalleryIntroBlockAttributes {
                className
                tag
                mediaUrl
            }
        }
    }
`;