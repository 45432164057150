import React from 'react';

export default function Status({ status }) {
  const { state, msg } = status;
  if (state === 'success') {
    return <p>{ msg }</p>
  }

  if (state === 'error') {
    return <p>{ msg }</p>
  }

  if (state === 'pending') {
    return <p>{ msg }</p>
  }

  if (state === 'underage') {
    return <p>{ msg }</p>
  }

  return null;
}