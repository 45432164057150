export default theme => ({
    contactPageIntro: {
        position: 'relative',
        marginBottom: 36,
        padding: '30px 0',
        [theme.breakpoints.up('md')]: {
            padding: '60px 0',
        },
        '& .MuiTypography-h1, & .MuiTypography-h2, & .MuiTypography-h3, & .MuiTypography-h4, & .MuiTypography-h5': {
            color: theme.palette.primary.main,
            marginBottom: 30,
            [theme.breakpoints.up('md')]: {
                marginBottom: 60,
            },
        },
        '&::before': {
            position: 'absolute',
            width: '100vw',
            left: '50%',
            top: 0,
            bottom: 0,
            backgroundColor: theme.palette.background.secondary,
            content: '""',
            transform: 'translateX(-50%)',
        },
        '& *': {
            position: 'relative',
        },
        '& .copy-col': {

            [theme.breakpoints.up('md')]: {
                padding: '12px 80px',
            }
        },
        '& .address-col, & .contact-col': {
            '& h3': {
                fontSize: 24,
                fontFamily: theme.bodyFont,
                fontWeight: 500,
                marginBottom: 24,
                marginTop: 0,
                lineHeight: 2,
                fontSize: 18,
            },
            '& p': {
                lineHeight: 1.5,
                fontWeight: 300,
                fontSize: 18,
                '& a': {
                    fontWeight: 500,
                    color: theme.palette.primary.main,
                    '& img': {
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        marginRight: 10,
                    },
                    '&:hover, &:focus': {
                        color: theme.palette.primary.dark,
                    }
                }
            }
        },
        '& .contact-col': {
            '& p': {
                '& a': {
                    fontSize: 16,
                },
                '&.social-links': {
                    marginTop: 24,
                    '& a': {
                        display: 'inline-block',
                        fontSize: 11,
                        padding: '11px 15px',
                        textTransform: 'uppercase',
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                        borderRadius: 8,
                        lineHeight: '16px',
                        marginRight: 24,
                        '& svg': {
                            width: 16,
                            height: 16,
                            display: 'inline-block',
                            verticalAlign: 'text-bottom',
                            marginRight: 12,
                        },
                        '&:hover, &:focus': {
                            color: theme.palette.primary.contrastText,
                            backgroundColor: theme.palette.primary.dark,
                        }
                    }
                }
            }
        }
    },
    contactPageIntroTheme: {
        ...theme.contactPageIntroTheme,
    }
})