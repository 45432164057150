import LinkArrow from '../../../images/link-arrow.png';
import WhiteCal from '../../../images/white-cal.png';
import GreyCal from '../../../images/grey-cal.png';

export default theme => ({
    latestPosts: {
        marginBottom: 36,
        [theme.breakpoints.up('lg')]: {
            width: 'calc(100vw - 72px)',
            marginLeft: '50%',
            transform: 'translateX(-50%)',
        },
        '& h2': {
            fontSize: 24,
            letterSpacing: '0.04em',
            lineHeight: '1.4',
            textTransform: 'uppercase',
            fontWeight: '600',
            '&::before': {
                content: '""',
                width: 32,
                height: 4,
                backgroundColor: theme.palette.primary.main,
                display: 'block',
                margin: '0 0 16px 0',
                [theme.breakpoints.up('md')]: {
                    margin: '6px 12px 6px 0',
                    display: 'inline-block',
                }
            },
            '&.mobile-title': {
                [theme.breakpoints.up('md')]: {
                    display: 'none',
                }
            },
            '&.desktop-title': {
                display: 'none',
                float: 'left',
                [theme.breakpoints.up('md')]: {
                    display: 'block',
                }
            }
        },
        '& .mobile-link, & .desktop-link': {
            color: theme.palette.primary.main,
            fontFamily: theme.headerFont,
            letterSpacing: '0.06em',
            lineHeight: '1.2',
            margin: '0 auto',
            textTransform: 'uppercase',
            fontSize: 16,
            display: 'table',
            '&::after': {
                backgroundImage: 'url(' + LinkArrow + ')',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                content: '""',
                marginLeft: 15,
                display: 'inline-block',
                width: 36,
                height: 13,
            }
        },
        '& .mobile-link': {
            [theme.breakpoints.up('md')]: {
                display: 'none',
            }
        },
        '& .desktop-link': {
            display: 'none',
            float: 'right',
            [theme.breakpoints.up('md')]: {
                display: 'block',
                margin: 0,
            }
        },
        [theme.breakpoints.up('md')]: {
            '& .latest-post-grid': {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'stretch',
            }
        },
        '& .newest': {
            width: 'calc(100% + 34.8px)',
            margin: '0 -17.4px',
            [theme.breakpoints.up('md')]: {
                width: 'calc(58% - 30px)',
                margin: 0,
                marginRight: 60,
            },
            '& a': {
                marginBottom: 45,
                display: 'block',
                height: 360,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                fontSize: 20,
                color: 'white',
                lineHeight: '1.15',
                letterSpacing: '-0.008em',
                position: 'relative',
                textDecoration: 'none',
                fontFamily: theme.headerFont,
                overflow: 'hidden',
                backgroundColor: theme.palette.secondary.main,
                [theme.breakpoints.up('md')]: {
                    height: '100%',
                    backgroundColor: 'transparent',
                },
                '& .gatsby-image-wrapper': {
                    '& > div': {
                        paddingBottom: '220px !important',
                    },
                    [theme.breakpoints.up('md')]: {
                        height: '100%',
                        '& > div': {
                            height: '100%',
                        },
                    },
                },
                '&::before': {
                    position: 'absolute',
                    zIndex: '1',
                    left: 0,
                    right: 0,
                    bottom: 140,
                    background: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 85%, black 100%)',
                    opacity: '0.6',
                    content: '""',
                    height: 152,
                    [theme.breakpoints.up('md')]: {
                        height: 330,
                        bottom: 0,
                    },
                },
                '& .tag': {
                    position: 'absolute',
                    zIndex: '1',
                    top: 20,
                    left: 17.4,
                    color: theme.palette.common.white,
                    fontSize: 12,
                    letterSpacing: '0.04em',
                    textTransform: 'uppercase',
                    fontFamily: theme.headerFont,
                    [theme.breakpoints.up('md')]: {
                        top: 40,
                        left: 40,
                        fontSize: 18,
                    },
                },
                '& .con-wrap' : {
                    position: 'absolute',
                    bottom: 30,
                    left: 17.4,
                    right: 17.4,
                    zIndex: '2',
                    [theme.breakpoints.up('md')]: {
                        left: 40,
                        right: 40,
                        bottom: 40,
                    },
                    '& .date': {
                        borderBottom: '1px solid',
                        borderColor: theme.palette.common.white,
                        display: 'block',
                        marginBottom: 16,
                        paddingBottom: 10,
                        fontWeight: '600',
                        fontFamily: theme.headerFont,
                        color: theme.palette.common.white,
                        letterSpacing: '0.04em',
                        lineHeight: 1.5,
                        fontSize: 12,
                        textTransform: 'uppercase',
                        '&::before': {
                            verticalAlign: 'sub',
                            content: '""',
                            display: 'inline-block',
                            width: 16,
                            height: 14,
                            backgroundImage: 'url(' + WhiteCal + ')',
                            backgroundSize: 'contain',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            marginRight: 10,
                        }
                    },
                    '& .title': {
                        color: theme.palette.common.white,
                        textTransform: 'uppercase',
                        fontSize: 16,
                        letterSpacing: '0.04em',
                        lineHeight: 1.5,
                        fontWeight: '600',
                        display: 'block',
                        [theme.breakpoints.up('md')]: {
                            fontSize: 24,
                        },
                    },
                    '& .more': {
                        color: theme.palette.primary.main,
                        fontFamily: theme.headerFont,
                        letterSpacing: '0.06em',
                        lineHeight: '1.2',
                        margin: '32px 0 0',
                        textTransform: 'uppercase',
                        fontSize: 16,
                        display: 'block',
                        '&::after': {
                            backgroundImage: 'url(' + LinkArrow + ')',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'contain',
                            backgroundPosition: 'center',
                            content: '""',
                            marginLeft: 15,
                            display: 'inline-block',
                            width: 36,
                            height: 13,
                        }
                    }
                },
            }
        },
        '& div.more': {
            [theme.breakpoints.up('md')]: {
                width: 'calc(42% - 30px)',
                margin: 0,
                padding: '40px 0',
            },
            '& .date': {
                clear: 'both',
                marginBottom: 16,
                fontWeight: '600',
                fontFamily: theme.headerFont,
                color: 'rgba(0,0,0,0.3)',
                letterSpacing: '0.04em',
                lineHeight: 1.5,
                fontSize: 14,
                textTransform: 'uppercase',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                '&::before': {
                    content: '""',
                    display: 'block',
                    width: 16,
                    height: 14,
                    backgroundImage: 'url(' + GreyCal + ')',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    marginRight: 10,
                    flex: '0 0 auto',
                },
                '&::after': {
                    flex: '1 0 auto',
                    content: '""',
                    display: 'block',
                    height: 2,
                    backgroundColor: 'rgba(0,0,0,0.3)',
                    marginLeft: 10,
                }
            },
            '& .more-link': {
                margin: '0 0 45px',
                textDecoration: 'none',
                fontSize: 18,
                letterSpacing: '0.04em',
                fontFamily: theme.headerFont,
                lineHeight: 1.4,
                color: theme.palette.primary.main,
                display: 'block',
                textTransform: 'uppercase',
            },
        }
    },
    latestPostsTheme: {
        ...theme.latestPostsTheme,
    }
})