import  React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {graphql, useStaticQuery} from "gatsby";
import ReactHtmlParser from 'react-html-parser';
import styles from './styles';
import Link from '../../Link';
import { Grid } from '@material-ui/core';
import {CoreHeadingBlock} from "../CoreHeadingBlock";

const useStyles = makeStyles(theme => styles(theme));

export const CgbLatestPostsGridBlock = (props) => {

    const classes = useStyles();

    const data = useStaticQuery(graphql`
      query LatestPostsGridList {
        allWpPost(sort: {order: DESC, fields: date}, limit: 3) {
          nodes {
            slug
            title
            date(formatString: "DD MMMM YYYY")
            featuredImage {
              node {
                mediaItemUrl
                altText
                localFile {
                  childImageSharp {
                    fluid {
                      src
                    }
                  }
                }
              }
            }
            Meta {
              metaDescription
            }
          }
        }
        wp {
          themeOptions {
            blogPath
            ThemeOptions {
              defaultImages {
                postFeaturedImage {
                  sourceUrl
                }
              }
            }
          }
        }
      }
    `);

    let defaultImage = <div className="gatsby-image-wrapper">
        <div style={{paddingBottom: '74%'}} />
        <img
            src={data.wp.themeOptions.ThemeOptions.defaultImages.postFeaturedImage.sourceUrl}
            style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center' }}
        />
    </div>;

    return (
        <div className={`latest-posts ${props.attributes.className ? props.attributes.className : ''} ${classes.latestPosts} ${classes.latestPostsTheme}`}>
          <CoreHeadingBlock 
              attributes={{
                  align: "",
                  anchor: "",
                  className: "",
                  content: ReactHtmlParser(props.attributes.sectionTitle),
                  level: 3,
                  textColor: "",
                  backgroundColor: "",
                  __typename: "WpCoreHeadingBlockAttributes",
              }}
              innerBlocks={[]} 
          />
          <Grid container spacing={10} direction="row" className="latest-posts-list">
            {data.allWpPost.nodes.map((post) => {
                  let image = null;
                  // This is a preview image
                  if(post.featuredImage == null) {
                      image = defaultImage;
                  } else {
                      image = <div className="gatsby-image-wrapper">
                          <div style={{paddingBottom: '74%'}} />
                          <img
                              src={post.featuredImage.node.localFile.childImageSharp.fluid.src}
                              alt={post.featuredImage?.node?.altText}
                              style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center' }}
                          />
                      </div>
                  }
                  return <Grid item xs={12} md={4}>
                    <div className="post-item">
                      {image}
                      <div className="post-content">
                        <p className="date">{post.date}</p>
                        <Link to={'/' + data.wp.themeOptions.blogPath + '/' + post.slug}>{post.title}</Link>
                        <p>{post.Meta.metaDescription}</p>
                      </div>
                    </div>
                  </Grid>;
              })}
          </Grid>
        </div>
    )
};

CgbLatestPostsGridBlock.propTypes = {
    attributes: PropTypes.object.isRequired
};

// This is the GraphQL Fragment that will be used within Gatsby queries
export const CgbLatestPostsGridBlockFragment = graphql`
  fragment CgbLatestPostsGridBlock on WpBlock {
    ... on WpCgbLatestPostsGridBlock {
      name
      attributes {
        __typename
        ... on WpCgbLatestPostsGridBlockAttributes {
            className
            sectionTitle
            catID
        }
      }
    }
  }`;

export const CgbLatestPostsGridBlockPreview = `
... on CgbLatestPostsGridBlock {
  name
  attributes {
    __typename
    ... on CgbLatestPostsGridBlockAttributes {
      className
      sectionTitle
      catID
    }
  }
}
`;