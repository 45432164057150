export default theme => ({
    imageContentRows: {
        position: 'relative',
        width: 'calc(100% + 34.8px)',
        margin: '0px -17.4px 36px',
        [theme.breakpoints.up('md')]: {
            margin: '0 -17.4px 36px',
        }
    },
    imageContentRowsTheme: {
        ...theme.imageContentRowsTheme,
    }
})