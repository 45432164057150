import React from 'react';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import ReactHtmlParser from 'react-html-parser';
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CgbFeaturedTextBlock = (props) => {
    const classes = useStyles();

    return <div className={`offer-grid-inner ${props.attributes.className ? props.attributes.className : ''} ${classes.featuredText} ${classes.featuredTextTheme}`}>
        <p>{ReactHtmlParser(props.attributes.text)}</p>
    </div>
};

export const CgbFeaturedTextBlockFragment = graphql`
  fragment CgbFeaturedTextBlock on WpBlock {
    ... on WpCgbFeaturedTextBlock {
        name
        attributes {
            ... on WpCgbFeaturedTextBlockAttributes {
                text
            }
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbFeaturedTextBlockPreview = `
    ... on CgbFeaturedTextBlock {
        name
        attributes {
            ... on CgbFeaturedTextBlockAttributes {
                text
            }
        }
    }
`;