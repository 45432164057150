export default theme => ({
   Calendar: {
    minWidth: 320,
    height: 500,
    marginBottom: 36,

    [theme.breakpoints.up('lg')]: {
      minWidth: 320,
      height: 720,
    }
  }
})