export default theme => ({
    imageLinkRowInner: {
        position: 'relative',
        marginBottom: 36,
        '& > span': {
            display: 'block',
            marginTop: 24,
        }
    },
    imageLinkRowInnerTheme: {
        ...theme.imageLinkRowInnerTheme,
    }
})