import linkArrow from '../../../images/arrow-right-purple.png';

export default theme => ({
    blockPostBanner: {
        position: 'relative',
        marginBottom: 36,
        paddingTop: 10,
        zIndex: 2,
        '& .gatsby-image-wrapper': {
            height: 'auto !important',
            width: '100% !important',
            '& > div': {
                paddingBottom: '68% !important',
            }
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: 32,
            marginBottom: -100,
        },
        '& .top': {
            backgroundColor: theme.palette.primary.main,
            '& *': {
                color: theme.palette.primary.contrastText,
            },
            '& .image': {
                fontSize: 0,
            },
            '& .content': {
                padding: '32px 24px',
                '& .MuiTypography-h1, & .MuiTypography-h2, & .MuiTypography-h3, & .MuiTypography-h4, & .MuiTypography-h5': {
                    margin: 0,
                    fontSize: 32,
                    letterSpacing: '0.008em',
                },
                '& p.date': {
                    fontWeight: '500',
                    fontSize: 12,
                    textTransform: 'uppercase',
                    letterSpacing: '0.0152em',
                    lineHeight: 1.33,
                    marginTop: 0,
                    marginBottom: 32,
                },
                [theme.breakpoints.up('md')]: {
                    padding: '80px 50px',
                    '& .MuiTypography-h1, & .MuiTypography-h2, & .MuiTypography-h3, & .MuiTypography-h4, & .MuiTypography-h5': {
    
                    },
                }
            }
        },
        '& .bottom': {
            '& .go-back': {
                display: 'block',
                backgroundColor: 'transparent',
                border: 'none',
                fontSize: 18,
                color: theme.palette.primary.main,
                padding: 0,
                fontFamily: theme.bodyFont,
                fontWeight: '500',
                lineHeight: 1.5,
                outline: 'none',
                marginTop: 32,
                [theme.breakpoints.up('md')]: {
                    marginTop: 60,
                },
                '&::before': {
                    content: '""',
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    marginRight: 10,
                    transform: 'rotate(180deg)',
                    width: 30,
                    height: 18,
                    backgroundImage: 'url(' + linkArrow + ')',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                }
            },
            '& .social-share': {
                float: 'right',
                display: 'flex',
                alignItems: 'center',
                marginTop: 32,
                fontSize: 16,
                letterSpacing: '0.025em',
                '& span': {
                    paddingRight: 10,
                },
                '& a': {
                    borderRadius: '50%',
                    marginLeft: 10,
                    dispay: 'inline-block',
                    color: 'white',
                    transition: 'overlay .3s ease-in-out',
                    width: 30,
                    height: 30,
                    padding: 5,
                    textAlign: 'center',
                    '&:hover, &:focus': {
                        opacity: 0.8,
                    },
                    '& svg': {
                        width: 12,
                        height: 12,
                    },
                    '&.twitter': {
                        backgroundColor: '#03A9F4',
                    },
                    '&.facebook': {
                        backgroundColor: '#385C8E',
                    }
                }
            }
        }
    },
    blockPostBannerTheme: {
        ...theme.blockPostBannerTheme,
    }
});