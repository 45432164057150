import React, {useState} from 'react';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import {buildBlocks} from "../../../services/builder";
import Img from "gatsby-image";
import Button from "@material-ui/core/Button/Button";
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CgbImageGalleryGridInnerSmallBlock = (props) => {
    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    const [active, setActive] = useState('');

    return <div className={`grid-gallery-inner-large ${props.attributes.display} ${props.attributes.className ? props.attributes.className : ''} ${classes.galleryGridInnerLarge} ${classes.galleryGridInnerLargeTheme}`}>
        { blocks[0].props.attributes.images.map((image) => {
            // if(image.fullUrlSharp){
            //     if(image.fullUrlSharp.childImageSharp){
            //         return <div className="image-item" onClick={() => setActive(image.id)} key={image.id}>
            //             <Img
            //                 fluid={image.fullUrlSharp.childImageSharp.fluid}
            //                 alt={image.alt ? image.alt : ''}
            //             />
            //         </div>
            //     } else {
            //         // Gifs do not have a childImageSharp
            //         return <div className="image-item" onClick={() => setActive(image.id)} key={image.id}>
            //             <img
            //                 src={image.fullUrlSharp.publicURL}
            //                 alt={image.alt ? image.alt : ''}
            //             />
            //         </div>
            //     }
            // } else {
                return <div className="image-item" onClick={() => setActive(image.id)} key={image.id}>
                    <div className="gatsby-image-wrapper">
                        <div style={{ paddingBottom: '50%' }} />
                        <img
                            src={image.url}
                            alt={image.alt ? image.alt : ''}
                            style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center' }}
                        />
                    </div>
                </div>
            // }
        })}
        { blocks[0].props.attributes.images.map((image) => {
            // if(image.fullUrlSharp){
            //     if(image.fullUrlSharp.childImageSharp){
            //         return <div className="full-screen-image" data-active={image.id === active ? true : false} key={'full-' + image.id}>
            //             <Button className="close-image" onClick={() => setActive('')}>Close <span></span></Button>
            //             <Img
            //                 fluid={image.fullUrlSharp.childImageSharp.fluid}
            //                 alt={image.alt ? image.alt : ''}
            //             />
            //         </div>
            //     } else {
            //         // Gifs do not have a childImageSharp
            //         return <div className="full-screen-image" data-active={image.id === active ? true : false} key={'full-' + image.id}>
            //             <Button className="close-image" onClick={() => setActive('')}>Close <span></span></Button>
            //             <img
            //                 src={image.fullUrlSharp.publicURL}
            //                 alt={image.alt ? image.alt : ''}
            //             />
            //         </div>
            //     }
            // } else {
                return <div className="full-screen-image" data-active={image.id === active ? true : false} key={'full-' + image.id}>
                    <Button className="close-image" onClick={() => setActive('')}>Close <span></span></Button>
                    <div className="gatsby-image-wrapper">
                        <div style={{ paddingBottom: '50%' }} />
                        <img
                            src={image.url}
                            alt={image.alt ? image.alt : ''}
                            style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center' }}
                        />
                    </div>
                </div>
            // }
        })}
    </div>
};

export const CgbImageGalleryGridInnerSmallBlockFragment = graphql`
  fragment CgbImageGalleryGridInnerSmallBlock on WpBlock {
    ... on WpCgbImageGalleryGridInnerSmallBlock {
        name
        attributes {
            className
            display
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbImageGalleryGridInnerSmallBlockPreview = `
    ... on CgbImageGalleryGridInnerSmallBlock {
        name
        attributes {
            className
            display
        }
    }
`;