import React, { Component } from 'react';


export default class Calendar extends Component {
  constructor(props) {
    super(props);
    this.injectCalendar = this.injectCalendar.bind(this);
  }

  componentDidMount() {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute('src', 'https://www.eventbrite.co.uk/static/widgets/eb_widgets.js');
    head.appendChild(script);

    this.injectCalendar();
  }

  injectCalendar() {
    if (!window.EBWidgets) {
      return setTimeout(this.injectCalendar, 200);
    };

    window.EBWidgets.createWidget({
      widgetType: 'checkout',
      eventId: this.props.id,
      iframeContainerId: `eventbrite-widget-container-${this.props.id}`,
      iframeContainerHeight: 425,  // Widget height in pixels. Defaults to a minimum of 425px if not provided
    });
  }

  render() {
    return <div className={`${this.props.classes.Calendar} calendly-inline-widget`} id={`eventbrite-widget-container-${this.props.id}`}></div>
  }
}