import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import { buildBlocks } from "../../../services/builder";
import Img from "gatsby-image";
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CgbBannerCalloutCtaBlock = (props) => {
    // console.log(props);
    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();
    // if( ! props.attributes.mediaUrl) return null;
    let image = null;

    // Use the gatsby image if available
    // if(props.attributes.mediaUrlSharp){
    //     if(props.attributes.mediaUrlSharp.childImageSharp){
    //         image = <Img
    //             className="desktop-bg"
    //             fluid={props.attributes.mediaUrlSharp.childImageSharp.fluid}
    //             alt=""
    //         />
    //     } else {
    //         // Gifs do not have a childImageSharp
    //         image = <img
    //             className="desktop-bg"
    //             src={props.attributes.mediaUrlSharp.publicURL}
    //             alt=""
    //         />
    //     }
    // } else {
        // This is a preview image
        image = <div className="gatsby-image-wrapper desktop-bg">
            <div style={{paddingBottom: '55%'}} />
            <img
                src={props.attributes.mediaUrl.charAt(0) == '/' ? 'https://brew-platform.staging.brew-systems.co.uk/' + props.attributes.mediaUrl : props.attributes.mediaUrl}
                alt=""
                style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center' }}
            />
        </div>
    // }
    let mobileImage = null;

    // Use the gatsby image if available
    // if(props.attributes.mediaUrlMobileSharp){
    //     if(props.attributes.mediaUrlMobileSharp.childImageSharp){
    //         mobileImage = <Img
    //             className="mobile-bg"
    //             fixed={props.attributes.mediaUrlMobileSharp.childImageSharp.fixed}
    //             alt=""
    //         />
    //     } else {
    //         // Gifs do not have a childImageSharp
    //         mobileImage = <img
    //             className="mobile-bg"
    //             src={props.attributes.mediaUrlMobileSharp.publicURL}
    //             alt=""
    //         />
    //     }
    // } else {
        // This is a preview image
        mobileImage = <div className="gatsby-image-wrapper mobile-bg">
            <div style={{paddingBottom: '55%'}} />
            <img
                src={props.attributes.mediaUrlMobile}
                alt=""
                style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center' }}
            />
        </div>
    // }

    return <div
        className={`banner-message ${props.attributes.className ? props.attributes.className : ''} ${classes.bannerCalloutCta} ${classes.bannerCalloutCtaTheme}`}
    >
        {image}
        {mobileImage}
        <div className="content-wrap">
            {blocks.map((block) => {
                return block;
            })}
        </div>
    </div>
};

CgbBannerCalloutCtaBlock.propTypes = {
    attributes: PropTypes.object.isRequired,
};

export const CgbBannerCalloutCtaBlockFragment = graphql`
    fragment CgbBannerCalloutCtaBlock on WpBlock {
        ... on WpCgbBannerCalloutCtaBlock {
            name
            attributes {
                __typename
                ... on WpCgbBannerCalloutCtaBlockAttributes {
                    className
                    mediaUrl
                    mediaUrlMobile
                }
            }
        }
    }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbBannerCalloutCtaBlockPreview = `
    ... on CgbBannerCalloutCtaBlock {
        name
        attributes {
            __typename
            ... on CgbBannerCalloutCtaBlockAttributes {
                className
                mediaUrl
                mediaUrlMobile
            }
        }
    }
`;