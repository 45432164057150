import React from 'react';
import PropTypes from 'prop-types';
import {graphql, useStaticQuery} from "gatsby";
import { makeStyles } from '@material-ui/styles';
import { buildBlocks } from "../../../services/builder";
import Typography from "@material-ui/core/Typography/Typography";
import Slider from "react-slick/lib";
import styles from "./styles";

const useStyles = makeStyles(theme => styles(theme));

export const CgbImageLinkSliderBlock = (props) => {
    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();
    
    let image = null;

    // Use the gatsby image if available
    // if(props.attributes.mediaUrlSharp){
    //     if(props.attributes.mediaUrlSharp.childImageSharp){
    //         image = props.attributes.mediaUrlSharp.publicURL;
    //     }
    // } else {
        // This is a preview image
        image = props.attributes.mediaUrl;
        if(image.charAt(0) == '/') {
            image = 'https://brew-platform.staging.brew-systems.co.uk/' + image;
        }
    // }

    const settings = {
        dots: true,
        infinite: true,
        centerMode: true,
        // lazyLoad: true,
        slidesToScroll: 1,
        variableWidth: true,
        arrows: false,
        // focusOnSelect: true,
    }

    return <div className={`image-link-slider ${props.attributes.className ? props.attributes.className : ''} ${classes.imageLinkSlider} ${classes.imageLinkSliderTheme}`} >
        <Typography component="h2" variant="h2" color="initial" align="center" >{props.attributes.title}</Typography>
        <Slider {...settings}>
            {blocks.map((block) => {
                return block;
            })}
        </Slider>
        <div className="bg-image" style={{backgroundImage: 'url(' + image + ')'}} />
    </div>
};

CgbImageLinkSliderBlock.propTypes = {
    attributes: PropTypes.object.isRequired,
};

export const CgbImageLinkSliderBlockFragment = graphql`
    fragment CgbImageLinkSliderBlock on WpBlock {
        ... on WpCgbImageLinkSliderBlock {
            name
            attributes {
                __typename
                ... on WpCgbImageLinkSliderBlockAttributes {
                    className
                    title
                    mediaUrl   
                }
            }
        }
    }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbImageLinkSliderBlockPreview = `
    ... on CgbImageLinkSliderBlock {
        name
        attributes {
            __typename
            ... on CgbImageLinkSliderBlockAttributes {
                className
                title
                mediaUrl
            }
        }
    }
`;