import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import { buildBlocks } from "../../../services/builder";
import ReactHtmlParser from 'react-html-parser';
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CgbBannerMessageBlock = (props) => {
    // console.log(props);
    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    return <div
        className={`banner-message ${props.attributes.className ? props.attributes.className : ''} ${classes.bannerMessage} ${classes.bannerMessageTheme}`}
    >
        {ReactHtmlParser(props.originalContent.replace(/1024px/g, "1440px"))}
        {blocks.map((block) => {
            return block;
        })}
    </div>
};

CgbBannerMessageBlock.propTypes = {
    attributes: PropTypes.object.isRequired,
};

export const CgbBannerMessageBlockFragment = graphql`
    fragment CgbBannerMessageBlock on WpBlock {
        ... on WpCgbBannerMessageBlock {
            name
            originalContent
            attributes {
                __typename
                ... on WpCgbBannerMessageBlockAttributes {
                    className
                    mediaUrl
                    mediaUrlMobile
                }
            }
        }
    }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbBannerMessageBlockPreview = `
    ... on CgbBannerMessageBlock {
        name
        originalContent
        attributes {
            __typename
            ... on CgbBannerMessageBlockAttributes {
                className
                mediaUrl
                mediaUrlMobile
            }
        }
    }
`;