export default theme => ({
    pageTitle: {
        position: 'relative',
        marginBottom: 36,
        height: 175,
        [theme.breakpoints.up('md')]: {
            height: 250,
        },
        '&::before': {
            content: '""',
            backgroundColor: theme.palette.primary.main,
            top: 0,
            bottom: 0,
            width: '100vw',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1,
            position: 'absolute',
            opacity: .5,
        },
        '& > .gatsby-image-wrapper': {
            height: '175px !important',
            marginLeft: '50%',
            transform: 'translateX(-50%)',
            maxWidth: '100vw !important',
            width: '100vw !important',
            zIndex: 0,
            [theme.breakpoints.up('md')]: {
                height: '250px !important',
            }
        },
        '& h1, & h2, & h3, & h4, & h5, & h6' : {
            position: 'absolute',
            top: '50%',
            height: 'fit-content',
            transform: 'translateY(-50%)',
            color: 'white',
            marginBottom: '0',
            zIndex: '3',
        },
    },
    pageTitleTheme: {
        ...theme.pageTitleTheme,
    }
});