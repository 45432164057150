import React from 'react';
import { graphql } from 'gatsby';
import { makeStyles } from '@material-ui/styles';
import styles from './styles';

import Calendar from './Calendar';

const useStyles = makeStyles(theme => styles(theme));

export const CgbCalendarAppBlock = (props) => {
    const classes = useStyles();

    return <Calendar classes={classes} id={props.attributes.iD} />;
};

export const CgbCalendarAppBlockFragment = graphql`
  fragment CgbCalendarAppBlock on WpBlock {
    ... on WpCgbCalendarAppBlock {
        name
        attributes {
            __typename
            ... on WpCgbCalendarAppBlockAttributes {
                className
                iD
            }
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbCalendarAppBlockPreview = `
    ... on CgbCalendarAppBlock {
        name
        attributes {
            __typename
            ... on CgbCalendarAppBlockAttributes {
                className
                iD
            }
        }
    }
`;