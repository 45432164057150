import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import Link from '../../Link';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CgbImageLinkSliderInnerBlock = (props) => {
    const classes = useStyles();
    
    let image = null;

    // Use the gatsby image if available
    // if(props.attributes.mediaUrlSharp){
    //     if(props.attributes.mediaUrlSharp.childImageSharp){
    //         image = props.attributes.mediaUrlSharp.publicURL;
    //     }
    // } else {
        // This is a preview image
        image = props.attributes.mediaUrl;
        if(image.charAt(0) == '/') {
            image = 'https://brew-platform.staging.brew-systems.co.uk/' + image;
        }
    // }

    return <Link to={props.attributes.linkURL} style={{backgroundImage: 'url(' + image + ')'}} className={`image-link-slider-inner ${props.attributes.className ? props.attributes.className : ''} ${classes.imageLinkSliderInner} ${classes.imageLinkSliderInnerTheme}`} >
        <span className="sub-text">{props.attributes.subText}</span>
        <span className="text">{props.attributes.text}</span>
        <ArrowForwardIcon/>
    </Link>
};

CgbImageLinkSliderInnerBlock.propTypes = {
    attributes: PropTypes.object.isRequired,
};

export const CgbImageLinkSliderInnerBlockFragment = graphql`
    fragment CgbImageLinkSliderInnerBlock on WpBlock {
        ... on WpCgbImageLinkSliderInnerBlock {
            name
            attributes {
                __typename
                ... on WpCgbImageLinkSliderInnerBlockAttributes {
                    className
                    subText
                    text
                    linkURL
                    mediaUrl
                }
            }
        }
    }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbImageLinkSliderInnerBlockPreview = `
    ... on CgbImageLinkSliderInnerBlock {
        name
        attributes {
            __typename
            ... on CgbImageLinkSliderInnerBlockAttributes {
                className
                subText
                text
                linkURL
                mediaUrl
            }
        }
    }
`;