export default theme => ({
    blogPagePosts: {
        position: 'relative',
        paddingBottom: 50,
        marginTop: 80,
        [theme.breakpoints.up('md')]: {
            paddingBottom: 100,
            marginTop: 128,
        },
        marginBottom: 36,
        '& .blog-list-banner': {
            padding: '50px 0',
            marginBottom: '36px',
            position: 'relative',
            [theme.breakpoints.up('md')]: {
                padding: '60px 0',
            },
            '&::before': {
                content: '""',
                display: 'block',
                backgroundColor: theme.palette.background.secondary,
                top: 0,
                position: 'absolute',
                bottom: 0,
                left: '50%',
                width: '100vw',
                transform: 'translateX(-50vw)',
            },
            '& h1': {
                position: 'relative',
                zIndex: '1',
                color: theme.palette.primary.main,
                fontFamily: theme.headerFont,
                fontSize: 36,
                lineHeight: 1.5,
                letterSpacing: '-.016em',
                fontWeight: '900',
                [theme.breakpoints.up('md')]: {
                    fontSize: 72,
                }
            }
        },
        '& .blog-list': {
            position: 'relative',
            zIndex: '1',
            [theme.breakpoints.up('md')]: {
                padding: '0 6.25%',
            },
            '& .featured-item': {
                position: 'relative',
                marginBottom: 36,
                '& .con-wrap': {
                    backgroundColor: theme.palette.primary.main + 'EE',
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    zIndex: 3,
                    padding: 25,
                    [theme.breakpoints.up('md')]: {
                        padding: '40px 50px',
                    },
                    '&::after': {
                        display: 'table',
                        clear: 'both',
                        content: '""',
                    },
                    '& .date': {
                        color: theme.palette.primary.contrastText,
                        fontSize: 12,
                        letterSpacing: '0.152em',
                        fontWeight: '500',
                        textTransform: 'uppercase',
                        margin: 0,
                        marginBottom: 10,
                    },
                    '& h2': {
                        fontFamily: theme.headerFont,
                        fontSize: 24,
                        lineHeight: 1.5,
                        fontWeight: '800',
                        letterSpacing: '0.008em',
                        color: theme.palette.primary.contrastText,
                        margin: 0,
                        [theme.breakpoints.up('md')]: {
                            fontSize: 32,
                            float: 'left',
                        }
                    },
                    '& a.cta': {
                        fontSize: 16,
                        lineHeight: 1.5,
                        display: 'inline-block',
                        marginTop: 16,
                        padding: '15px 50px',
                        borderRadius: 6,
                        backgroundColor: theme.palette.primary.contrastText,
                        color: theme.palette.primary.main,
                        fontWeight: '500',
                        boxShadow: '0 4px 6px rgba(0,0,0,0.25)',
                        transition: 'opacity .3s ease-in-out',
                        '&:hover, &:focus': {
                            opacity: 0.8,
                        },
                        [theme.breakpoints.up('md')]: {
                            fontSize: 17,
                            marginTop: 0,
                            marginLeft: 32,
                            float: 'right',
                        }
                    }
                }
            },
            '& .list-item': {
                marginBottom: 36,
                '& .gatsby-image-wrapper': {
                    overflow: 'hidden',
                    '& > div': {
                        paddingBottom: '78% !important',
                    },
                    [theme.breakpoints.up('md')]: {
                        width: '50%',
                        float: 'left',
                    }
                },
                '& .con-wrap': {
                    padding: '24px 35px',
                    [theme.breakpoints.up('md')]: {
                        width: '50%',
                        float: 'left',
                        padding: '70px 80px',
                    },
                    '& .date': {
                        opacity: .5,
                        fontSize: 12,
                        letterSpacing: '0.152em',
                        fontWeight: '500',
                        textTransform: 'uppercase',
                        margin: 0,
                        marginBottom: 10,
                    },
                    '& h2': {
                        color: theme.palette.primary.main,
                        fontFamily: theme.bodyFont,
                        fontSize: 24,
                        lineHeight: 1.4,
                        marginBottom: 16,
                        marginTop: 0,
                        fontWeight: '500',
                    },
                    '& p': {
                        fontSize: 14,
                        lineHeight: 1.5,
                        marginBottom: 32,
                        letterSpacing: '0.032em',
                        [theme.breakpoints.up('md')]: {
                            fontSize: 18,
                        },
                    },
                    '& .cta': {
                        fontSize: 16,
                        lineHeight: 1.5,
                        display: 'inline-block',
                        marginTop: 16,
                        padding: '15px 50px',
                        borderRadius: 6,
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                        fontWeight: '500',
                        boxShadow: '0 4px 6px rgba(0,0,0,0.25)',
                        transition: 'background-color .3s ease-in-out',
                        '&:hover, &:focus': {
                            backgroundColor: theme.palette.primary.dark,
                        },
                        [theme.breakpoints.up('md')]: {
                            fontSize: 17,
                        }
                    }
                },
                '&::after': {
                    clear: 'both',
                    display: 'table',
                    content: '""',
                }
            }
        },
        '& ul.MuiPagination-ul': {
            justifyContent: 'center',
        }
    },
    blogPagePostsTheme: {
        ...theme.blogPagePostsTheme,
    }
})