export default theme => ({
    twoColInner: {
        position: 'relative',
        [theme.breakpoints.up('md')]: {
            marginBottom: 36,
        }
    },
    twoColInnerTheme: {
        ...theme.twoColInnerTheme,
    }
})