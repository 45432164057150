import ReactHtmlParser from 'react-html-parser';
import { graphql } from "gatsby";

export const CoreImageBlock = (props) => {
  // console.log(props);

  return ReactHtmlParser(props.originalContent);
};

export const CoreImageBlockFragment = graphql`
  fragment CoreImageBlock on WpBlock {
    ... on WpCoreImageBlock {
      name
      originalContent
      attributes {
        __typename
        ... on WpCoreImageBlockAttributes {
          align
          alt
          caption
          className
          href
          id
          linkClass
          linkDestination
          linkTarget
          rel
          sizeSlug
          title
          url     
        }
      }
    }
  }`;

  
export const CoreImageBlockPreview = `
  ... on CoreImageBlock {
    name
    originalContent
    attributes {
      __typename
      ... on CoreImageBlockAttributes {
        align
        alt
        caption
        className
        height
        href
        id
        linkClass
        linkDestination
        linkTarget
        rel
        sizeSlug
        title
        url
      }
    }
  }`;