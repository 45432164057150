export default theme => ({
    blogPostWrapper: {
        position: 'relative',
        marginBottom: 36,
        padding: '50px 0',
        '& .MuiGrid-item': {
            '& > h1, & > h2, & > h3, & > h4, & > h5, & > h6, > p, > ul, > ol': {
                marginBottom: 38,
            },
            '& > .wp-block-image' : {
                width: 'calc(100% + 36px)',
                margin: '0 -18px',
                marginBottom: 36,
                [theme.breakpoints.up('md')]: {
                    width: 'calc((100% / 8) * 9)',
                    margin: '0 0 36px calc( -8px - (100% / 8)) !important',
                },
                '& figcaption': {
                    fontSize: 24,
                    padding: 24,
                    fontWeight: 'bold',
                    color: theme.palette.primary.contrastText,
                    lineHeight: 1.33,
                    letterSpacing: '0.008em',
                    margin: '-72px 0 0 calc(100% / 9)',
                    backgroundColor: theme.palette.primary.main,
                    position: 'relative',
                    zIndex: 1,
                    fontFamily: theme.headerFont,
                    [theme.breakpoints.up('md')]: {
                        padding: '80px 75px',
                        fontSize: 32,
                        marginTop: -115
                    }
                }
            },
            '& > div:not(.button-group)' : {
                width: 'calc(100% + 52px) !important',
                margin: '0 -26px 36px !important',
                [theme.breakpoints.up('md')]: {
                    width: 'calc(((100% / 8) * 12) + 16px) !important',
                    margin: '0 0 36px  calc( -8px - (100% / 4)) !important',
                }
            },
        },
        '& span.image-with-caption': {
            display: 'block',
        }
    },
})