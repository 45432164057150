import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import { buildBlocks } from "../../../services/builder";
import styles from './styles';

import ReactPlayer from 'react-player';
import videoPoster from "../../../video/poster.jpg";
import playButton from "../../../images/play-button.png";

const useStyles = makeStyles(theme => styles(theme));

export const CgbLandingVideoBlock = (props) => {
    // console.log(props);
    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    return <div
        className={`landing-video ${props.attributes.className ? props.attributes.className : ''} ${classes.landingVideo}`}
        style={{backgroundImage: 'url(' + videoPoster + ')'}}
    >
        <ReactPlayer
            url={'https://vimeo.com/542626724'}
            height={'auto'}
            width={'100%'}
            controls={1}
            playing={1}
            light={1}
            loop={1}
            playIcon={<img className="play-button" src={playButton} alt="play" />}
        />
    </div>
};

CgbLandingVideoBlock.propTypes = {
    attributes: PropTypes.object.isRequired,
};

export const CgbLandingVideoBlockFragment = graphql`
    fragment CgbLandingVideoBlock on WpBlock {
        ... on WpCgbLandingVideoBlock {
            name
            originalContent
            attributes {
                __typename
                ... on WpCgbLandingVideoBlockAttributes {
                    className
                }
            }
        }
    }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbLandingVideoBlockPreview = `
    ... on CgbLandingVideoBlock {
        name
        originalContent
        attributes {
            __typename
            ... on CgbLandingVideoBlockAttributes {
                className
            }
        }
    }
`;