export default theme => ({
    imageLinkSliderInner: {
        display: 'flex',
        width: 205,
        height: 300,
        padding: 20,
        flexDirection: 'column',
        justifyContent: 'flex-end',
        boxShadow: '0 4px 6px rgba(0,0,0,0.32)',
        color: 'white',
        backgroundColor: theme.palette.secondary.dark,
        borderRadius: 6,
        textDecoration: 'none !important',
        position: 'relative',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        overflow: 'hidden',
        '&:hover, &:focus': {
            textDecoration: 'none',
            '& svg': {
                marginLeft: 24,
            }
        },
        '&::before': {
            position: 'absolute',
            top: 15,
            left: '0',
            right: '0',
            bottom: '0',
            content: '""',
            display: 'block',
            background: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.6) 80%,rgba(0,0,0,1) 100%)',
            opacity: '0.7',
        },
        '& svg': {
            width: 32,
            height: 32,
            marginLeft: 0,
            transition: 'margin-left 250ms ease-in-out',
            position: 'relative',
            zIndex: '1'
        },
        '& .text': {
            fontFamily: theme.headerFont,
            fontSize: 24,
            lineHeight: '1.1',
            letterSpacing: '0.008em',
            position: 'relative',
            zIndex: '1'
        },
        '& .sub-text': {
            fontFamily: theme.subHeaderFont,
            fontSize: 14,
            lineHeight: '1.5',
            letterSpacing: '0.038em',
            fontWeight: '600',
            position: 'relative',
            zIndex: '1'
        }
    },
    imageLinkSliderInnerTheme: {
        // ...theme.imageLinkSliderTheme,
    }
})