import React from 'react';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import {buildBlocks} from "../../../services/builder";
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CgbTwoCardSplitBlock = (props) => {
    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    return <div className={`two-card-split ${props.attributes.className ? props.attributes.className : ''} ${classes.twoCardSplit} ${classes.twoCardSplitTheme}`}>
        {blocks[0]}
        {blocks[1]}
    </div>
};

export const CgbTwoCardSplitBlockFragment = graphql`
  fragment CgbTwoCardSplitBlock on WpBlock {
    ... on WpCgbTwoCardSplitBlock {
        name
        attributes {
            className
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbTwoCardSplitBlockPreview = `
    ... on CgbTwoCardSplitBlock {
        name
        attributes {
            className
        }
    }
`;