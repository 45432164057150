import themePalette from "../../../../.brew-cache/theme.json";

export default theme => ({
    root: {
        '& button': {
            fontFamily: theme.bodyFont,
            padding: '12px 22px 14px',
            boxShadow: '0 4px 6px rgba(0,0,0,0.5)',
            fontSize: 17,
            lineHeight: 1.2,
            fontWeight: 500,
            textAlign: 'center',
            minWidth: 185,
            textTransform: 'none',
            // Foreground colours
            '&[data-text-color="primary"]': { color: themePalette.ThemeOptions.mainColours.primaryColour},
            '&[data-text-color="secondary"]': { color: themePalette.ThemeOptions.mainColours.secondaryColour},
            '&[data-text-color="tertiary"]': { color: themePalette.ThemeOptions.mainColours.tertiaryColour},
            '&[data-text-color="foreground_primary"]': { color: themePalette.ThemeOptions.foregroundColours.foregroundPrimaryColour},
            '&[data-text-color="foreground_secondary"]': { color: themePalette.ThemeOptions.foregroundColours.foregroundSecondaryColour},
            '&[data-text-color="foreground_tertiary"]': { color: themePalette.ThemeOptions.foregroundColours.foregroundTertiaryColour},
            '&[data-text-color="background_primary"]': { color: themePalette.ThemeOptions.backgroundColours.backgroundPrimaryColour},
            '&[data-text-color="background_secondary"]': { color: themePalette.ThemeOptions.backgroundColours.backgroundSecondaryColour},
            '&[data-text-color="background_tertiary"]': { color: themePalette.ThemeOptions.backgroundColours.backgroundTertiaryColour},
            // Background colours
            '&[data-bg-color="primary"]': { 
                backgroundColor: themePalette.ThemeOptions.mainColours.primaryColour,
                '&:hover, &:focus': {
                    backgroundColor: theme.palette.primary.dark,
                }
            },
            '&[data-bg-color="secondary"]': { 
                backgroundColor: themePalette.ThemeOptions.mainColours.secondaryColour,
                '&:hover, &:focus': {
                    backgroundColor: theme.palette.secondary.dark,
                }
            },
            '&[data-bg-color="tertiary"]': { 
                backgroundColor: themePalette.ThemeOptions.mainColours.tertiaryColour,
                '&:hover, &:focus': {
                    backgroundColor: theme.palette.tertiary.dark,
                }
            },
            '&[data-bg-color="foreground_primary"]': { 
                backgroundColor: themePalette.ThemeOptions.foregroundColours.foregroundPrimaryColour
            },
            '&[data-bg-color="foreground_secondary"]': { 
                backgroundColor: themePalette.ThemeOptions.foregroundColours.foregroundSecondaryColour
            },
            '&[data-bg-color="foreground_tertiary"]': { 
                backgroundColor: themePalette.ThemeOptions.foregroundColours.foregroundTertiaryColour
            },
            '&[data-bg-color="background_primary"]': { 
                backgroundColor: themePalette.ThemeOptions.backgroundColours.backgroundPrimaryColour
            },
            '&[data-bg-color="background_secondary"]': { 
                backgroundColor: themePalette.ThemeOptions.backgroundColours.backgroundSecondaryColour
            },
            '&[data-bg-color="background_tertiary"]': { 
                backgroundColor: themePalette.ThemeOptions.backgroundColours.backgroundTertiaryColour
            },
        }
    },
    buttonTheme: {
        ...theme.buttonTheme,
    }
})