import React from 'react';
import {graphql, useStaticQuery} from "gatsby";
import Helmet from 'react-helmet';
import { makeStyles } from '@material-ui/styles';
import Link from "../../Link";
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import { Grid } from '@material-ui/core';
import styles from './styles';
import { buildBlocks } from "../../../services/builder";

const useStyles = makeStyles(theme => styles(theme));

export const CgbSocialSharingBlock = (props) => {
    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    const data = useStaticQuery(graphql`
      query urlGrab {
        wp {
          locationData {
            LocationData {
              url
              facebookHandle
              twitterHandle
            }
          }
        }
      }
    `);

    return <div className={`social-sharing ${props.attributes.className ? props.attributes.className : ''} ${classes.socialSharing} ${classes.socialSharingTheme}`}>
        <h2>Social Feed</h2>
        <Grid container spacing={3} direction="row">
            <Grid item xs={12} md={6}>
              <Grid container spacing={3} direction="row">
                <Grid item xs={12} sm={6}>
                  {blocks[0]}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {blocks[1]}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <a class="twitter-timeline" data-theme="light" data-height="400" href="https://twitter.com/sinaiparkhouse">Tweets by sinaiparkhouse</a>
              <p className="link-list">
                <Link to={"https://www.facebook.com/" + data.wp.locationData.LocationData.facebookHandle}>Join us on Facebook<span><FacebookIcon/></span></Link>
                <Link to="https://www.linkedin.com/company/sinai-park-house">Connect on LinkedIn<span><LinkedInIcon/></span></Link>
              </p>
            </Grid>
        </Grid>
        
          <Helmet>
            <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
          </Helmet>
    </div>
};

export const CgbSocialSharingBlockFragment = graphql`
  fragment CgbSocialSharingBlock on WpBlock {
    ... on WpCgbSocialSharingBlock {
        name
        attributes {
            className
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbSocialSharingBlockPreview = `
    ... on CgbSocialSharingBlock {
        name
        attributes {
            className
        }
    }
`;