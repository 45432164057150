export default theme => ({
    gallery: {
        position: 'relative',
        marginBottom: 36,
        '& .wp-block-gallery': {
            margin: 0,
            '& ul.blocks-gallery-grid': {
                margin: 0,
                paddingLeft: 0,
                position: 'relative',
                '&::before': {
                    [theme.breakpoints.up('sm')]: {
                        backgroundColor: theme.palette.secondary.main,
                        width: 50,
                        height: 50,
                        top: '50%',
                        left: '55%',
                        transform: 'translate(-50%, -50%) rotate(45deg)',
                        position: 'absolute',
                        zIndex: -1,
                        content: '""',
                        display: 'block',
                    },
                    [theme.breakpoints.up('md')]: {
                        width: 120,
                        height: 120,
                        left: '50%',

                    }
                },
                '&::after': {
                    clear: 'both',
                    display: 'table',
                    content: '""',
                },
                '& li': {
                    display: 'block',
                    marginBottom: 8,
                    '& figure': {
                        margin: 0,
                    },
                    '&:nth-of-type(1)': {
                        [theme.breakpoints.up('sm')]: {
                            marginRight: 8,
                            width: '55%',
                        },
                        [theme.breakpoints.up('md')]: {
                            width: '48%',
                            marginBottom: '20%',
                        }
                    },
                    '&:nth-of-type(2)': {
                        width: 'calc(50% - 4px)',
                        float: 'left',
                        [theme.breakpoints.up('sm')]: {
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            left: 'calc(55% + 8px)',
                            width: 'calc(45% - 8px)',
                            marginBottom: 0,
                        },
                        [theme.breakpoints.up('md')]: {
                            top: '13%',
                            width: '42%',
                            left: '52%',
                            right: '6%',
                        }
                    },
                    '&:nth-of-type(3)': {
                        width: 'calc(50% - 4px)',
                        float: 'right',
                        [theme.breakpoints.up('sm')]: {
                            position: 'absolute',
                            bottom: 0,
                            right: 0,
                            left: 'calc(55% + 8px)',
                            width: 'calc(45% - 8px)',
                            marginBottom: 0,
                        },
                        [theme.breakpoints.up('md')]: {
                            bottom: 0,
                            width: '40%',
                            left: '32%',
                            right: '28%',
                            zIndex: 3,
                        }
                    }
                }
            }
        }
    },
})