import React from 'react';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CgbIconListInnerBlock = (props) => {
    const classes = useStyles();

    return <li className={`icon-list ${props.attributes.className ? props.attributes.className : ''} ${classes.iconListInner} ${classes.iconListInnerTheme}`}>
        <div className="gatsby-image-wrapper desktop-bg">
            <div style={{paddingBottom: '24px'}} />
            <img
                src={props.attributes.mediaUrl.charAt(0) == '/' ? 'https://brew-platform.staging.brew-systems.co.uk/' + props.attributes.mediaUrl : props.attributes.mediaUrl}
                alt=""
                style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center' }}
            />
        </div>
        {props.attributes.text}
    </li>
};

export const CgbIconListInnerBlockFragment = graphql`
  fragment CgbIconListInnerBlock on WpBlock {
    ... on WpCgbIconListInnerBlock {
        name
        attributes {
            className
            text
            mediaUrl
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbIconListInnerBlockPreview = `
    ... on CgbIconListInnerBlock {
        name
        attributes {
            className
            text
            mediaUrl
        }
    }
`;