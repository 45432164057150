export default theme => ({
    twoCardSplit: {
        position: 'relative',
        marginBottom: 36,
        width: '100vw',
        marginLeft: '50%',
        transform: 'translateX(-50%)',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            flexDirection: 'row',
        },
        '& .card': {
            marginBottom: 0,
            boxShadow: 'none',
            padding: '32px 32px 0',
            [theme.breakpoints.up('md')]: {
                maxWidth: '50%',
                flex: '0 0 50%',
            },
            '& .con-wrap': {
                paddingLeft: 0,
                paddingRight: 0,
            }
        }
    },
    twoCardSplitTheme: {
        ...theme.twoCardSplitTheme,
    }
})