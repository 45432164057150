import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from "gatsby";
import { makeStyles } from '@material-ui/styles';
import { buildBlocks } from "../../../services/builder";
import styles from "./styles";
import {CoreHeadingBlock} from "../CoreHeadingBlock";
import ReactHtmlParser from 'react-html-parser';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => styles(theme));

export const CgbImageLinkRowBlock = (props) => {
    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    return <div className={`image-link-row ${props.attributes.className ? props.attributes.className : ''} ${classes.imageLinkRow} ${classes.imageLinkRowTheme}`} >
        <CoreHeadingBlock 
            attributes={{
                align: "",
                anchor: "",
                className: "",
                content: ReactHtmlParser(props.attributes.ilrSectionTitle),
                level: 3,
                textColor: "",
                backgroundColor: "",
                __typename: "WpCoreHeadingBlockAttributes",
            }}
            innerBlocks={[]} 
        />
        <Grid container spacing={3} direction="row" className="link-grid">
            {blocks.map((block) => {
                return block;
            })}
        </Grid>
    </div>
};

CgbImageLinkRowBlock.propTypes = {
    attributes: PropTypes.object.isRequired,
};

export const CgbImageLinkRowBlockFragment = graphql`
    fragment CgbImageLinkRowBlock on WpBlock {
        ... on WpCgbImageLinkRowBlock {
            name
            attributes {
                __typename
                ... on WpCgbImageLinkRowBlockAttributes {
                    className
                    ilrSectionTitle 
                }
            }
        }
    }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbImageLinkRowBlockPreview = `
    ... on CgbImageLinkRowBlock {
        name
        attributes {
            __typename
            ... on CgbImageLinkRowBlockAttributes {
                className
                ilrSectionTitle 
            }
        }
    }
`;