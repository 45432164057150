import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import ReactHtmlParser from 'react-html-parser';
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CoreGalleryBlock = (props) => {
    const classes = useStyles();

    return <div className={classes.gallery}>{ReactHtmlParser(props.originalContent)}</div>
};

CoreGalleryBlock.propTypes = {
    attributes: PropTypes.object.isRequired,
};

export const CoreGalleryBlockFragment = graphql`
  fragment CoreGalleryBlock on WpBlock {
    ...on WpCoreGalleryBlock {
      name
      originalContent
      attributes {
        __typename
        ...on WpCoreGalleryBlockAttributes {
          align
          caption
          className
          columns
          ids
          imageCrop
          images {
            alt
            caption
            fullUrl
            id
            link
            url             
          }
          linkTo
        }
      }
    }
  }`;

  // This is the string version of this that is used within previews and without WP prefixes
export const CoreGalleryBlockPreview = `
...on CoreGalleryBlock {
  name
  originalContent
  attributes {
    __typename
    ...on CoreGalleryBlockAttributes {
      align
      caption
      className
      columns
      ids
      imageCrop
      images {
        alt
        caption
        fullUrl
        id
        link
        url
      }
      linkTo
    }
  }
}
`;