import  React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {graphql, useStaticQuery} from "gatsby";
import Link from '../../Link';
import Typography from "@material-ui/core/Typography/Typography";
import Img from "gatsby-image";
import ReactHtmlParser from 'react-html-parser';
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CgbLatestPostsBlock = (props) => {

    const classes = useStyles();

    const data = useStaticQuery(graphql`
      query LatestPostsList {
        wp {
            themeOptions {
              blogPath
            }
          }
          allWpPost(sort: {order: DESC, fields: date}) {
            nodes {
              slug
              title
              date
              featuredImage {
                node {
                  mediaItemUrl
                }
              }
            }
          }
      }
    `);

    let image = null;

    // Use the gatsby image if available
    if(data.wp.posts.nodes[0].featuredImage){
        // if(data.wp.posts.nodes[0].featuredImage.node.mediaItemUrlSharp){
        //     if(data.wp.posts.nodes[0].featuredImage.node.mediaItemUrlSharp.childImageSharp){
        //         image = <Img
        //             fluid={data.wp.posts.nodes[0].featuredImage.node.mediaItemUrlSharp.childImageSharp.fluid}
        //             alt=""
        //         />
        //     } else {
        //         // Gifs do not have a childImageSharp
        //         image = <img
        //             src={data.wp.posts.nodes[0].featuredImage.node.mediaItemUrlSharp.publicURL}
        //             alt=""
        //         />
        //     }
        // } else {
            // This is a preview image
            image = <div className="gatsby-image-wrapper">
                <div style={{paddingBottom: '55%'}} />
                <img
                    src={data.wp.posts.nodes[0].featuredImage.node.mediaUrl}
                    alt=""
                    style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center' }}
                />
            </div>
        // }
    }

    var dateFormat= require('dateformat');

    let d0 = '';
    let d1 = '';
    let d2 = '';
    let d3 = '';

    if(data.wp.posts.nodes[0] != null) {
        d0 = new Date(data.wp.posts.nodes[0].date);
    }

    if(data.wp.posts.nodes[1] != null) {
        d1 = new Date(data.wp.posts.nodes[1].date);
    }

    if(data.wp.posts.nodes[2] != null) {
        d2 = new Date(data.wp.posts.nodes[2].date);
    }

    if(data.wp.posts.nodes[3] != null) {
        d3 = new Date(data.wp.posts.nodes[3].date);
    }

    return (
        <div className={`latest-posts ${props.attributes.className ? props.attributes.className : ''} ${classes.latestPosts} ${classes.latestPostsTheme}`}>
            <Typography component='h2' variant='h2' color='initial' align='left' className="mobile-title">{ReactHtmlParser(props.attributes.sectionTitle)}</Typography>
            <div className="latest-post-grid">
                <div className="newest">
                    <Link to={'/' + data.wp.themeOptions.blogPath + '/' + data.wp.posts.nodes[0].slug + '/'} className="more-link">
                        {image}
                        <span className="tag">Featured</span>
                        <span className="con-wrap">
                            <span className="date">{dateFormat(d0, "dS mmmm yyyy")}</span>
                            <span className="title">{data.wp.posts.nodes[0].title}</span>
                            <span className="more">Read More</span>
                        </span>
                    </Link>
                </div>
                <div className="more">
                    <Typography component='h2' variant='h2' color='initial' align='left' className="desktop-title">{props.attributes.sectionTitle}</Typography>
                    <Link to={'/' + data.wp.themeOptions.blogPath + '/'} style={{ textDecoration: 'none' }} className="desktop-link">{props.attributes.btnText}</Link>
                    {data.wp.posts.nodes[1] ?
                        <>
                            <p className="date">{dateFormat(d1, "dS mmmm yyyy")}</p>
                            <Link to={'/' + data.wp.themeOptions.blogPath + '/' + data.wp.posts.nodes[1].slug + '/'} className="more-link">{data.wp.posts.nodes[1].title}</Link>
                        </>
                    : '' }

                    {data.wp.posts.nodes[2] ? 
                        <>
                            <p className="date">{dateFormat(d2, "dS mmmm yyyy")}</p>
                            <Link to={'/' + data.wp.themeOptions.blogPath + '/' + data.wp.posts.nodes[2].slug + '/'} className="more-link">{data.wp.posts.nodes[2].title}</Link>
                        </>
                    : '' }

                    {data.wp.posts.nodes[3] ? 
                        <>
                            <p className="date">{dateFormat(d3, "dS mmmm yyyy")}</p>
                            <Link to={'/' + data.wp.themeOptions.blogPath + '/' + data.wp.posts.nodes[3].slug + '/'} className="more-link">{data.wp.posts.nodes[3].title}</Link>
                        </>
                    : '' }
                    <Link to={'/' + data.wp.themeOptions.blogPath + '/'} style={{ textDecoration: 'none' }} className="mobile-link">{props.attributes.btnText}</Link>
                </div>
            </div>
        </div>
    )
};

CgbLatestPostsBlock.propTypes = {
    attributes: PropTypes.object.isRequired
};

// This is the GraphQL Fragment that will be used within Gatsby queries
export const CgbLatestPostsBlockFragment = graphql`
  fragment CgbLatestPostsBlock on WpBlock {
    ... on WpCgbLatestPostsBlock {
      name
      attributes {
        __typename
        ... on WpCgbLatestPostsBlockAttributes {
            className
            sectionTitle
            btnText
        }
      }
    }
  }`;

export const CgbLatestPostsBlockPreview = `
... on CgbLatestPostsBlock {
  name
  attributes {
    __typename
    ... on CgbLatestPostsBlockAttributes {
      className
      sectionTitle
      btnText
    }
  }
}
`;