import React from 'react';
import PropTypes from 'prop-types';
import { graphql, navigate, useStaticQuery } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import Pagination from '@material-ui/lab/Pagination';
import themeOptions from "../../../../.brew-cache/theme.json";
import Link from '../../Link';
import Typography from "@material-ui/core/Typography/Typography";
import Img from "gatsby-image";
import { replaceMasks } from "../../../services/masks";
import styles from './styles';
import ReactHtmlParser from 'react-html-parser';

const useStyles = makeStyles(theme => styles(theme));

export const CgbBlogPagePostsBlock = (props) => {
    const classes = useStyles();
    /**
     * The blog posts should be passed through from the parent page directly to this component
     * This will only happen on custom page templates like the blog list page 
     * Preview will not have the posts information for this page due to how the query works, so if this not set
     * use some placeholder data to populate the posts
     * */ 
    let { pageNumber, totalPages, featuredPost } = props.pageContext;
    let { posts } = props.parentAttributes;

    // console.log(props);

    const data = useStaticQuery(graphql`
      query DefaultFeaturedImage {
        wp {
            themeOptions {
              ThemeOptions {
                defaultImages {
                  postFeaturedImage {
                    sourceUrl
                  }
                }
              }
            }
          }
      }
    `);

    // Toggle output based on Attributes
    // props.attributes.display : list / grid

    if( typeof(posts)==='undefined') {
        // set some sample data
        posts = [
            {
                "node": {
                    title: "Example Post",
                    date: "2020-01-01 00:00:00",
                    excerpt: "<p>This is just a test post used for previewing the listing page. Real posts will appear here when this page is published.</p>",
                    uri: '/',
                    featuredImage: {
                        node: {
                            mediaItemUrl: "",
                            altText: ""
                        }
                    }
                }
            }
        ]
    }

    let defaultImage = <div className="gatsby-image-wrapper">
        <div style={{paddingBottom: '78%'}} />
        <img
            src={data.wp.themeOptions.ThemeOptions.defaultImages.postFeaturedImage.sourceUrl}
            style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center' }}
        />
    </div>;

    let featuredPostImage = null;

    if(featuredPost !== null) {
      // This is a preview image
      if(featuredPost.featuredImage == null) {
        featuredPostImage = defaultImage;
      } else {
        featuredPostImage = <div className="gatsby-image-wrapper">
              <div style={{paddingBottom: 540}} />
              <img
                  src={featuredPost.featuredImage.node.localFile.childImageSharp.fluid.src}
                  alt={featuredPost.featuredImage?.node?.altText}
                  style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center' }}
              />
          </div>
      }

    }

    return (
        <div className={`cgbBlogPagePostsBlock ${props.attributes.className ? props.attributes.className : ''} ${classes.blogPagePosts} ${classes.blogPagePostsTheme}`}>
            <div className="blog-list-banner">
                <Typography component='h1' variant='h1' color='initial' align='left' >{props.attributes.listingTitle}</Typography>
            </div>
            <div className="blog-list">
              {featuredPost !== null &&
                <div className="featured-item">
                  {featuredPostImage}
                  <div className="con-wrap">
                    <p className="date">{featuredPost.date}</p>
                    <h2>{featuredPost.title}</h2>
                    <Link to={`${featuredPost.uri}`} style={{ textDecoration: 'none' }} className="cta">
                        {props.attributes.btnText}
                    </Link>
                  </div>
                </div>
              }
                {posts.map((post) => {

                    let image = null;
                    // This is a preview image
                    if(post.node.featuredImage == null) {
                        image = defaultImage;
                    } else {
                        image = <div className="gatsby-image-wrapper">
                            <div style={{paddingBottom: '78%'}} />
                            <img
                                src={post.node.featuredImage.node.localFile.childImageSharp.fluid.src}
                                alt={post.node.featuredImage?.node?.altText}
                                style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center' }}
                            />
                        </div>
                    }

                    return <div className="list-item" key={post.node.id}>
                        {image}
                        <div className="con-wrap">
                          <p className="date">{post.node.date}</p>
                          <h2>{post.node.title}</h2>
                          <p>{ReactHtmlParser(replaceMasks(post.node.excerpt.replace(/(<([^>]+)>)/gi, "")))}</p>

                          <Link to={`${post.node.uri}`} style={{ textDecoration: 'none' }} className="cta">
                              {props.attributes.btnText}
                          </Link>
                        </div>
                    </div>
                })}
            </div>
            {totalPages > 1 &&
                <Pagination count={totalPages} page={pageNumber} onChange={(e, value) => { value === 1 ? navigate(`/${themeOptions.blogPath}`) : navigate(`/${themeOptions.blogPath}/page/${value}`) }}/>
            }
        </div>
    )
};

CgbBlogPagePostsBlock.propTypes = {
    attributes: PropTypes.object.isRequired
};

// This is the GraphQL Fragment that will be used within Gatsby queries
export const CgbBlogPagePostsBlockFragment = graphql`
  fragment CgbBlogPagePostsBlock on WpBlock {
    ... on WpCgbBlogPagePostsBlock {
      name
      attributes {
        __typename
        ... on WpCgbBlogPagePostsBlockAttributes {
            className
            display
            btnText
            listingTitle
        }
      }
    }
  }`;

export const CgbBlogPagePostsBlockPreview = `
... on CgbBlogPagePostsBlock {
  name
  attributes {
    __typename
    ... on CgbBlogPagePostsBlockAttributes {
      className
      display
      btnText
      listingTitle
    }
  }
}
`;