import React from 'react';
import { graphql, useStaticQuery } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import {buildBlocks} from "../../../services/builder";
import Grid from '@material-ui/core/Grid';
import Link from '../../Link';
import styles from './styles';

import phoneIcon from '../../../images/icon-phone-purple.png';
import emailIcon from '../../../images/icon-email-purple.png';
import pinIcon from '../../../images/icon-map-pin-purple.png';

import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';

const useStyles = makeStyles(theme => styles(theme));

export const CgbContactPageIntroBlock = (props) => {
    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    const data = useStaticQuery(graphql`
      query cpiFetch {
        wp {
          locationData {
            LocationData {
                name
                address1
                address2
                town
                region
                postcode
                telephone
                email
                twitterHandle
                facebookHandle
            }
          }
        }
      }
    `);

    // console.log(data);

    return <div className={`cp-intro ${props.attributes.className ? props.attributes.className : ''} ${classes.contactPageIntro} ${classes.contactPageIntroTheme}`}>
        <Grid container spacing={3} direction="row">
            <Grid item xs={12} >
                {blocks[0]}
            </Grid>
            <Grid item xs={12} md={6} className="copy-col">
                {blocks[1]}
            </Grid>
            <Grid item xs={12} sm={6} md={3} className="address-col">
                <h3>Address</h3>
                <p>
                    {data.wp.locationData.LocationData.name !== null &&
                    <span>{data.wp.locationData.LocationData.name}, <br/></span>
                    }
                    {data.wp.locationData.LocationData.address1 !== null &&
                    <span>{data.wp.locationData.LocationData.address1}, <br/></span>
                    }
                    {data.wp.locationData.LocationData.address2 !== null &&
                    <span>{data.wp.locationData.LocationData.address2}, <br/></span>
                    }
                    {data.wp.locationData.LocationData.town !== null &&
                    <span>{data.wp.locationData.LocationData.town}, <br/></span>
                    }
                    {data.wp.locationData.LocationData.region !== null &&
                    <span>{data.wp.locationData.LocationData.region}, <br/></span>
                    }
                    {data.wp.locationData.LocationData.postcode !== null &&
                    <strong>{data.wp.locationData.LocationData.postcode} </strong>
                    }
                </p>
            </Grid>
            <Grid item xs={12} sm={6} md={3} className="contact-col">
                <h3>Contact</h3>
                <p><Link to={'tel:' + data.wp.locationData.LocationData.telephone} ><img src={phoneIcon} /> {data.wp.locationData.LocationData.telephone}</Link></p>
                <p><Link to={'mailto:' + data.wp.locationData.LocationData.email} ><img src={emailIcon} /> {data.wp.locationData.LocationData.email}</Link></p>
                <p className="social-links">
                    {data.wp.locationData.LocationData.twitterHandle !== null &&
                        <Link to={"https://www.twitter.com/" + data.wp.locationData.LocationData.twitterHandle}><TwitterIcon/>Twitter</Link>
                    }
                    {data.wp.locationData.LocationData.facebookHandle !== null &&
                        <Link to={"https://www.facebook.com/" + data.wp.locationData.LocationData.facebookHandle}><FacebookIcon/>Facebook</Link>
                    }
                </p>
            </Grid>
        </Grid>
    </div>
};

export const CgbContactPageIntroBlockFragment = graphql`
  fragment CgbContactPageIntroBlock on WpBlock {
    ... on WpCgbContactPageIntroBlock {
        name
        attributes {
            className
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbContactPageIntroBlockPreview = `
    ... on CgbContactPageIntroBlock {
        name
        attributes {
            className
        }
    }
`;