export default theme => ({
    imageLinkRow: {
        position: 'relative',
        marginBottom: 36,
        paddingTop: 50,
        [theme.breakpoints.up('md')]: {
            paddingTop: 100,
        },
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: '50%',
            width: '100vw',
            transform: 'translateX(-50%)',
            backgroundColor: theme.palette.background.secondary,
        },
        '& .link-grid': {
            paddingTop: 38,
            paddingBottom: 50,
            [theme.breakpoints.up('md')]: {
                paddingTop: 118,
                paddingBottom: 130,
            },
        }
    },
    imageLinkRowTheme: {
        ...theme.imageLinkRowTheme,
    }
})